.paginator_selector{
  ion-select{
    padding: 0 30px 0 50px;
    min-height: 40px;
  }
  span{
    z-index: -1;
    margin-right: -45px;
    cursor: pointer;
  }
  ion-icon{
    z-index: -1;
    margin-left: -25px;
    cursor: pointer;
  }
}