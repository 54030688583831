
.resource_list{
  .item{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--light-grey);
    &:last-child{
      border-color: transparent;
    }
  }
  @media (max-width: 767px) {
    margin: 0 5px;
  }
}

/* .card_resources{
  margin: 0 !important;
  ion-card-content{
    padding: 25px 40px 30px !important;
    @media (max-width: 767px) {
      padding: 20px !important;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 20px !important;
  }
}
.card_search{
  box-shadow: 1px 2px 10px rgba(238, 238, 238, 0.8);
  border-radius: 5px;
  margin-bottom: 30px !important;
  @media (max-width: 767px) {
    margin-bottom: 15px !important;
  }
}
.publications{
  ion-card-title a{
    font-weight: bold;
  }
} */