
.circle{
  display: block;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  position: relative;
  margin: 0 auto;
  ion-icon{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.success{
    background: rgba(91, 185, 71, 0.1);
  }
  &.error{
    background: rgba(239, 78, 76, 0.1);
  }
  @media (max-width: 767px){
    width: 60px;
    height: 60px;
    ion-icon{
      width: 24px;
      height: 24px;
    }
  }
}