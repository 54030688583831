.card_cat_filters{
  box-shadow: 1px 2px 10px rgba(238, 238, 238, 0.8);
  border-radius: 5px;
  padding: 10px 20px;
  @media (max-width: 767px) {
    margin: 0;
    padding: 10px 10px;
    display: block;
  }
  ion-button.active{
    &::part(native){
      color: var(--ion-color-primary) !important;
    }
  }
  ion-select{
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    min-height: 30px;
  }
  ion-icon{
    font-size: 24px;
  }
}

ion-select::part(icon){
  display: none !important;
}

.search_bar{
  padding-inline-end: 0px;
  padding-inline-start: 0px;
  padding-top: 0;
  padding-bottom: 0;
  ion-icon{
    top: 14px !important;
  }
  @media (max-width: 991px) {
    margin-top: 10px;
  }
}

.card_filters{
  padding: 5px 20px 5px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  ion-select{
    padding: 0;
    border: none;
    &::part(placeholder){
      --placeholder-color: var(--black);
    }
  }
  .card_filters_column2{
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.filter,
.sort{
  ion-icon{
    font-size: 24px;
  }
}

.filter_button{
  font-weight: 700 !important;
  &::part(native){
    border-color: #F0F2F7;
    padding-right: 16px;
    padding-left: 16px;
    height: 40px;
  }
  ion-icon{
    --ion-color-dark: var(--light) !important;
  }
  .filter_button_number{
    background-color: var(--ion-color-primary);
    color: #FFF;
    margin-right: 10px;
  }
  &.active{
    &::part(native){
      border-color: var(--accent);
    }
  }
  &.clear{
    &::part(native){
      border-color: transparent;
      margin-left: 5px;
    }
  }
}
.select_filter{
  margin-left: .25em;
  margin-right: .25em;
  margin-top: -10px;
  div::after{
    display: none;
  }
  ul{
    width: 100%;
    ion-item{
      white-space: nowrap !important;
    }
  }
  .tag{
    padding: 3px 6px;
    border-radius: 5px;
    font-weight: 800;
    font-size: 11px;
  }
  .filter_selected_number{
    background-color: var(--light-grey);
    color: var(--sad-purple);
    margin-left: 10px;
  }
}

.d_md_flex{
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }
}
.mb_sm_2{
  margin-bottom: .5em;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.check_filter_button{
  padding: 0px 5px 0px 14px;
  border: 1px solid var(--light-grey);
  border-radius: 20px;
  max-height: 38px;
  display: inline-block;
  &::part(native){
    padding: 0;
    background: transparent;
    min-height: 38px;
  }
  ion-checkbox{
    margin-top: 0;
    margin-bottom: 2px;
  }
  ion-label{
    font-size: 13px !important;
    margin-bottom: 2px;
  }
  @media (max-width: 767px) {

  }
}

.sort_selector{
  ion-select{
    padding: 0 30px 0 50px;
    min-height: 40px;
  }
  span{
    z-index: -1;
    margin-right: -45px !important;
    cursor: pointer;
  }
  ion-icon{
    z-index: -1;
    margin-left: -25px;
    cursor: pointer;
  }
}