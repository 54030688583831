
.date_input {
  &.react-datepicker-wrapper{
      width: auto;
  }
  ion-input{
    input{
      padding-left: 50px !important;
    }
    &::before{
      content: "";
      position: absolute;
      top: calc(50% - 9px);
      left: 16px;
      width: 18px;
      height: 18px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMTI1IDE2LjAzMTJDMS4xMjUgMTYuMjU1IDEuMjEzODkgMTYuNDY5NiAxLjM3MjEzIDE2LjYyNzlDMS41MzAzNiAxNi43ODYxIDEuNzQ0OTcgMTYuODc1IDEuOTY4NzUgMTYuODc1SDE2LjAzMTJDMTYuMjU1IDE2Ljg3NSAxNi40Njk2IDE2Ljc4NjEgMTYuNjI3OSAxNi42Mjc5QzE2Ljc4NjEgMTYuNDY5NiAxNi44NzUgMTYuMjU1IDE2Ljg3NSAxNi4wMzEyVjYuNzVIMS4xMjVWMTYuMDMxMlpNMTYuODc1IDMuMDg1NjZDMTYuODc0OSAyLjk3NTI4IDE2Ljg1MjkgMi44NjYwMSAxNi44MTAzIDIuNzY0MTZDMTYuNzY3OCAyLjY2MjMgMTYuNzA1NSAyLjU2OTg4IDE2LjYyNzEgMi40OTIyQzE2LjU0ODYgMi40MTQ1MiAxNi40NTU2IDIuMzUzMTMgMTYuMzUzNCAyLjMxMTU2QzE2LjI1MTEgMi4yNjk5OSAxNi4xNDE2IDIuMjQ5MDcgMTYuMDMxMiAyLjI1SDE0LjA2NTNWMS4xMjVIMTIuMzc3OFYyLjI1SDUuNjIyMTlWMS4xMjVIMy45MzQ2OVYyLjI1SDEuOTY4NzVDMS44NTgzNyAyLjI0OTA3IDEuNzQ4OSAyLjI2OTk5IDEuNjQ2NjQgMi4zMTE1NkMxLjU0NDM4IDIuMzUzMTMgMS40NTEzNSAyLjQxNDUyIDEuMzcyOTIgMi40OTIyQzEuMjk0NSAyLjU2OTg4IDEuMjMyMjEgMi42NjIzIDEuMTg5NjYgMi43NjQxNkMxLjE0NzExIDIuODY2MDEgMS4xMjUxNCAyLjk3NTI4IDEuMTI1IDMuMDg1NjZWNS4wNjI1SDE2Ljg3NVYzLjA4NTY2WiIgZmlsbD0iI0E5QTlBOSIvPgo8L3N2Zz4K")
    }
  }
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{
  display: none;
}
.react-datepicker{
  font-family: 'Jakarta';
  border-color: var(--ion-color-primary);
}
.react-datepicker__current-month{
  margin-bottom: 10px;
  color: #FFF;
}
.react-datepicker__day-names{
  margin-top: 10px;
}
.react-datepicker__day-name{
  color: #FFF;
}
.react-datepicker__header{
  background-color: var(--ion-color-primary);
  color: #FFF;
  border-color: var(--ion-color-primary);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous{
  height: 18px;
  width: 18px;
  top: 8px;
}
.react-datepicker__navigation:hover *::before{
  border-color: var(--light-grey);
}
.react-datepicker__navigation--next{
  right: 10px;
}
.react-datepicker__navigation--previous{
  left: 10px;
}
.react-datepicker__year-read-view--selected-year{
  font-size: 24px;
}
.react-datepicker__year-read-view--down-arrow{
  top: 10px;
}
.react-datepicker__year-option{
  color: var(--grey);
  padding-top: 2px;
  padding-bottom: 2px;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected{
  background-color: var(--ion-color-primary);
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__navigation-icon::before{
  border-color: #FFF;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow{
  border-top-color: var(--light-grey);
}
.react-datepicker__year-dropdown{
  background-color: #FFF;
}
.react-datepicker__year-option:hover{
  background-color: var(--light-grey);
}
.react-datepicker__navigation--years-previous,
.react-datepicker__navigation--years-upcoming{
  &::after{
    content: "";
    position: absolute;
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    display: block;
    height: 9px;
    width: 9px;
    left: 10px;
    top: 4px;
    transform: rotate(135deg);
  }
}
.react-datepicker__navigation--years-upcoming{
  &::after{
    transform: rotate(-45deg);
    top: 16px;
  }
}

.react-datepicker-popper {
  z-index: 9999;
}