@import "./scss/fonts.scss";
@import "./scss/marginPadding.scss";

:root {
  --ion-toolbar-border-color: var(--light-grey);
  --border-color: var(--light-grey);
  --ion-item-border-color: var(--light-grey);
  --ion-border-color: var(--light-grey);
  --ripple-color: transparent !important;
  --color-focused: transparent;
  --color-activated: transparent;
  --background-activated: transparent;
  --inner-padding-top: 5px;
  --inner-padding-bottom: 5px;
}

.notification ion-col {
  width: unset !important;
  flex: unset !important;
  max-width: unset !important;
}

.notification .mb-2:nth-child(2) {
  margin-left: 20px;
}

html {
  height: 100vh;
}

.ios,
.md {
  .title-large {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}

ion-title {
  &.with-back-button {
    padding-left: 0;
    padding-right: 44px;
  }
}

.header-collapse-condense ion-toolbar:first-of-type {
  padding-top: 10px;
  padding-bottom: 10px;
}

hr {
  background: var(--light-grey);
  margin: 0;

  &.card-line-padding {
    width: calc(100% - 15px);
    transform: translateX(15px);
  }
}

.no-padding {
  padding: 0;
}

ion-avatar {
  width: 50px;
  height: 50px;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

ion-item {
  overflow: visible;
}

.d-md-flex {
  display: flex !important;

  @media (max-width: 767px) {
    display: block !important;
  }
}

.d-sm-flex {
  display: block !important;

  @media (max-width: 767px) {
    display: flex !important;
  }
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.hide {
  display: none;
}

.no-mobile {
  @media (max-width: 767px) {
    display: none;
  }
}

.no-desktop {
  @media (min-width: 768px) {
    display: none;
  }
}

.underline {
  text-decoration: underline;
}

.mx-auto {
  margin: 0 auto;
}

.cursor_pointer {
  cursor: pointer;
}

.ion-ripple-effect {
  display: none;
}

.ion-activatable {
  background: transparent;
}

.no-padding-horizontal {
  padding-inline-start: 10px !important;
  padding-inline-end: 0 !important;

  @media (max-width: 767px) {
    padding-inline-start: 0 !important;
  }
}

.toolbar-title-default {
  --ion-background-color: transparent;
  margin-top: 124px;

  ion-buttons{
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    margin-top: 60px;

    ion-title.md {
      margin-top: 60px;
      padding-bottom: 0;
    }

    ion-title.ios {
      margin-top: 30px;
      padding-bottom: 0;
    }

    ion-buttons {
      margin-bottom: 50px;
      margin-top: 10px;
    }
  }

  /* @media (max-width: 440px) {
    ion-title{
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 15px;
      max-width: 320px;
      padding-right: 20px;
    }
  } */
}

ion-toolbar.ios {
  --min-height: 70px;
}

.no_button {
  ion-title.ios {
    margin-top: 0px;
  }

  ion-title.md {
    margin-top: 20px;

      &.noMarginTop{
        margin-top: 0;
      }

    @media (max-width: 767px) {
      padding: 0 10px
    }
  }

  @media (max-width: 767px) {
    ion-toolbar.ios {
      margin-top: 70px;
      height: 60px;
      --min-height: 60px;

      ion-title.ios {
        height: 60px;
        margin-top: 0px;
        padding-top: 0;
      }
    }
  }

  @media (max-width: 550px) {
    ion-toolbar.ios {
      margin-top: 50px;
    }
  }
}

.with_button {
  ion-title.ios {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    ion-toolbar.ios {
      margin-top: 60px;
      height: 110px;

      ion-title.ios {
        height: 50px;
        margin-top: 50px;
        padding-top: 0;
      }
    }

    ion-title.md {
      position: absolute;
      top: 0;
      max-width: 100%;
    }
  }

  @media (max-width: 550px) {
    ion-toolbar.ios {
      margin-top: 40px;
    }
  }
}

.c-pointer {
  cursor: pointer;
}

.item-simulator {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 15px;
}

/* -----------------------------------------------------
	POPOVER
----------------------------------------------------- */

/* 
  - Jira ticket: https://zoetis.atlassian.net/browse/WEB-671
  - This is a workaround for a bug where the popover is not shown in newer browsers.
  - The official workaround didn't work for us, because the popover events weren't being triggered.
    - Link: https://github.com/ionic-team/ionic-framework/issues/27599#issuecomment-1584711091
  - The following unofficial workaround comes from https://stackoverflow.com/a/76381282
*/
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.popover-content {
  padding: 22px 15px 15px 15px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
  border: none;
}

.select-popover {
  --backdrop-opacity: 0;

  .popover-content {
    --width: 180px;
    padding: 0px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
    border: none;

    ion-radio {
      margin-left: 10px;
    }

    .popover-viewport {
      overflow: hidden;
    }

    ion-item {
      &::part(native) {
        padding-left: 0;
      }
    }

    ion-list {
      padding: 0;
    }
  }
}

/* -----------------------------------------------------
	DOTS
----------------------------------------------------- */
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(135, 135, 135, 0.1);
  position: relative;
  display: block;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-right: 15px;

    &.dot-inline {
      margin-right: 10px !important;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgba(135, 135, 135, 1);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.dot-inline {
    margin-right: 10px !important;
  }

  &.creating,
  &.shipping,
  &.registered {
    background: rgba(169, 169, 169, 0.1);

    &:before {
      background: rgba(135, 135, 135, 1);
    }
  }

  &.receiving,
  &.received {
    background: rgba(249, 165, 36, 0.1);

    &:before {
      background: rgba(249, 165, 36, 1);
    }
  }

  &.extraction,
  &.preparation,
  &.sequencing,
  &.analysis,
  &.processing {
    background: rgba(0, 188, 241, 0.1);

    &:before {
      background: rgba(0, 188, 241, 1);
    }
  }

  &.failed {
    background: rgba(239, 78, 76, 0.1);

    &:before {
      background: rgba(239, 78, 76, 1);
    }
  }

  &.reportDone {
    background: rgba(76, 81, 162, 0.1);

    &:before {
      background: rgba(76, 81, 162, 1);
    }
  }
}

.label-dot {
  display: flex !important;
  align-items: center;
}

/* -----------------------------------------------------
	TEXT, TITLES, HEADINGS, ETC.
----------------------------------------------------- */
:root {
  --ion-card-color: var(--black);
}

ion-card {
  border-radius: 5px !important;
}

/*
h1,
.card-content-ios h1, .card-content-md h1,
.sc-ion-label-ios-s h1, .sc-ion-label-md-s h1{
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  @media (max-width: 767px) {
      font-size: 21px;
      line-height: 30px;
  }
}
h2,
.card-content-ios h2, .card-content-md h2,
.sc-ion-label-ios-s h2, .sc-ion-label-md-s h2{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-top: 2px;
    margin-bottom: 2px;
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
    }
}
h3,
.card-content-ios h3, .card-content-md h3,
.sc-ion-label-ios-s h3, .sc-ion-label-md-s h3{
  font-size: 16px;
  line-height: 20px;
  color: var(--grey);
  font-weight: 400;
  @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
  }
}
h4,
.card-content-ios h4, .card-content-md h4,
.sc-ion-label-ios-s h4, .sc-ion-label-md-s h4{
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  @media (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
  }
}
h5,
.card-content-ios h5, .card-content-md h5,
.sc-ion-label-ios-s h5, .sc-ion-label-md-s h5{
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
  }
}
h6,
.card-content-ios h6, .card-content-md h6,
.sc-ion-label-ios-s h6, .sc-ion-label-md-s h6{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #999999;
  @media (max-width: 767px) {
      font-size: 9px;
      line-height: 10px;
  }
}
.h3{
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: var(--black);
} */
.list-ios,
.list-md {
  padding-top: 8px;
  padding-bottom: 8px;
}

.list-avatar-name {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 24px;
  padding-left: 10px;

  @media (max-width: 767px) {
    font-size: 16px !important;
    line-height: 18px;
  }
}

.tags {
  background: var(--light-grey);
  color: var(--grey);
  padding: 2px 6px 3px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;

  &.have-updates {
    background: var(--black);
    color: #FFFFFF;
  }

  &.have-kits {
    background: #9A9A9A;
    color: #FFFFFF;
  }

  &.registered,
  &.processing,
  &.received,
  &.failed,
  &.reported,
  &.completed {
    color: var(--ion-color-light);
  }

  &.registered {
    background: var(--purple);
  }

  &.received {
    background: var(--light-blue);
  }

  &.processing {
    background: var(--ion-color-warning);
  }

  &.failed {
    background: var(--ion-color-danger);
  }

  &.reported,
  &.completed {
    background: var(--ion-color-success);
  }

  @media (max-width: 767px) {
    font-size: 12px !important;
    line-height: 16px !important;
    margin-right: 5px;
  }
}

.subtitle-s1 {
  font-size: 16px !important;
  line-height: 18px !important;
  text-transform: uppercase;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.subtitle-s2 {
  font-size: 12px !important;
  line-height: 14px !important;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 !important;

  @media (max-width: 767px) {
    font-size: 10px !important;
    line-height: 10px !important;
  }
}

.running_head {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.lead {
  font-weight: 300;
  font-size: 21px;
  line-height: 30px;
}

.display_4 {
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 45px !important;
}

p {
  &.b1 {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.b2 {
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.b2-fixed-sm {
    font-size: 12px;
    line-height: 16px;
  }

  &.b2-fixed-lg {
    font-size: 16px;
    line-height: 20px;
  }

  &.b3 {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 16px;
    }
  }

  &.creating,
  &.shipping,
  &.registered {
    color: rgba(135, 135, 135, 1) !important;
  }

  &.receiving,
  &.received {
    color: rgba(249, 165, 36, 1) !important;
  }

  &.extraction,
  &.preparation,
  &.sequencing,
  &.analysis,
  &.processing {
    color: rgba(0, 188, 241, 1) !important;
  }

  &.failed {
    color: rgba(239, 78, 76, 1) !important;
  }
}

/* -----------------------------------------------------
	CARDS
----------------------------------------------------- */
.card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  margin-inline: 15px;
  margin-top: 10px;
  margin-bottom: 10px;

  .card-footer {
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
      max-width: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        border-right: 1px solid var(--light-grey);

        @media (min-width: 768px) {
          border-right: 0px;
        }
      }
    }
  }

  .card-content-md,
  .card-content-ios {
    padding: 25px;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.list-ios-lines-none .item {
  padding: 2px 0;
}

ion-item ion-button {
  margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

ion-item ion-input ion-button {
  margin-top: 0px;
  margin-bottom: 0px;
}

.label-overflow {
  margin-right: 20px;
  position: relative;
  text-overflow: inherit !important;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100px;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 41.49%);
  }
}

/* -----------------------------------------------------
	BUTTONS & LINKS
----------------------------------------------------- */
.button {
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
  --height: 40px;
  min-height: 40px;
  --transition: all .2s ease-in-out;

  &::part(native) {
    padding-right: 18px;
    padding-left: 18px;

    @media (max-width: 767px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  ion-icon {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
    --height: 30px;
    min-height: 30px;

    ion-icon {
      font-size: 15px;
    }
  }

  &.button-remove {
    margin-bottom: 15px;
    outline: none;
  }

  &.button-clear {
    &::part(native) {
      padding-right: 0px;
      padding-left: 3px;
      overflow: visible;

      &::after {
        margin: 0 -15px 0 -10px;
        border-radius: 100px;
      }
    }
  }

  &.button-solid {
    --box-shadow: none !important;
  }

  &.icon-end {
    ion-icon {
      margin-left: 10px;

      @media (max-width: 767px) {
        margin-left: 8px;
      }
    }
  }

  &.icon-start {
    ion-icon {
      margin-right: 10px;

      @media (max-width: 767px) {
        margin-right: 8px;
      }
    }
  }

  span {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &.button-form-check {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: var(--dark);
    display: inline-block;
    margin-left: 0;
    opacity: 1;
    min-height: auto;
    margin: 0;

    &::part(native) {
      padding-right: 0 !important;
      padding-left: 4px !important;
    }

    ion-icon {
      color: var(--light);
    }
  }

  &.button-cero-padding::part(native) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  &.button-search {
    height: 40px;
    margin-left: -20px;

    &::part(native) {
      border-radius: 35px;
    }
  }

  &.ion-color-secondary {

    &:hover,
    &:active,
    &:focus {
      &::part(native) {
        --ion-color-base: var(--ion-color-secondary-shade);
      }
    }
  }

  &.ion-color-success {

    &:hover,
    &:active,
    &:focus {
      &::part(native) {
        --ion-color-base: var(--ion-color-success-shade);
      }
    }
  }

  &.ion-color-primary {

    &:hover &:focus,
    &:active {
      &::part(native) {
        color: var(--ion-color-primary);
        --background: rgba(69, 116, 193, .1);
      }
    }
  }

  &.ion-color-tertiary {
    &:hover {
      &::part(native) {
        color: var(--accent);
      }

      ion-icon {
        --ion-color-tertiary: var(--accent) !important;
      }

      svg,
      svg path {
        fill: var(--accent);
      }
    }

    &:focus,
    &:active {
      &::part(native) {
        color: var(--accent);
        --background: var(--accent-light);
      }

      ion-icon {
        --ion-color-tertiary: var(--accent) !important;
      }

      svg,
      svg path {
        fill: var(--accent);
      }
    }
  }

  &.ion-color-medium {
    &:hover {
      &::part(native) {
        color: var(--dark);
      }

      ion-icon {
        --ion-color-medium: var(--dark) !important;
      }
    }

    &:focus,
    &:active {
      &::part(native) {
        color: var(--dark);
        --background: var(--light-grey);
      }

      ion-icon {
        --ion-color-medium: var(--dark) !important;
      }
    }
  }
}

ion-button ion-label {
  overflow: visible;
}

.sc-ion-buttons-md-s ion-button,
.sc-ion-buttons-ios-s ion-button {
  font-weight: bold;
  font-size: 15px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.button-hide {
  background-color: transparent !important;
  pointer-events: none !important;
}

.button-dark {
  color: var(--grey);

  &:hover {
    color: var(--ion-color-primary);
  }
}

.button-outline {
  --border-width: 1px;
}

.button-link {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.button_underline {
  text-decoration: underline;
}

ion-icon.grey {
  color: #AAAAAA;
}

.link,
a {
  font-weight: 500;
  text-decoration: none;
  transition: .3 ease-in-out;
  background-color: transparent;
  color: var(--ion-color-primary);

  &:hover {
    color: var(--ion-color-secondary);
  }
}

.button.default {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: background-color .15s ease-in-out, color .15s ease-in-out;
  -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
  -ms-transition: background-color .15s ease-in-out, color .15s ease-in-out;
  -o-transition: background-color .15s ease-in-out, color .15s ease-in-out;

  &::part(native) {
    background-color: #FAFAFA;
    color: var(--dark-grey);
  }

  &:hover,
  &:focus,
  &:active {
    &::part(native) {
      background-color: rgba(91, 52, 188, 0.1);
      color: var(--ion-color-primary);
    }
  }

  ion-icon {
    margin-left: 3px;
    transition: transform .15s ease-in-out;
    -webkit-transition: transform .15s ease-in-out;
    -ms-transition: transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
  }

  ion-label {
    display: flex;
    align-items: center;
  }

  &.active {
    &::part(native) {
      background-color: rgba(91, 52, 188, 0.1);
      color: var(--ion-color-primary);
    }

    ion-icon {
      color: var(--ion-color-primary);
      transform: rotate(180deg);
    }
  }
}

/* Hover states */
.button.ion-color-primary:hover,
.button.ion-color-light:hover,
.button.button-default:hover,
.button.ion-color-tertiary:hover {
  --ion-color-base: var(--light-blue) !important;
}

.button.button-outline.ion-color-primary:hover {
  ion-icon {
    color: var(--light-blue) !important;
  }
}

.button.ion-color-light:hover::part(native) {
  --ion-color-contrast: #FFFFFF;
}

/* a:hover,
.button.ion-color-primary:hover,
.button.button-link.ion-color-primary:hover{
    color: var(--light-blue);
} */
a:hover,
.button.button-outline.ion-color-primary:hover,
.button.button-link.ion-color-primary:hover {
  color: var(--light-blue);

  ion-icon {
    --ion-color-primary: var(--light-blue);
  }
}

/* .ion-activated{
    background: transparent !important;
} */
ion-button,
ion-item,
ion-back-button {
  --ripple-color: transparent !important;
  --background-hover-opacity: 0 !important;
}

.sc-ion-buttons-md-s ion-button {
  --padding-start: 20px;
  --padding-end: 20px;
}

/* ion-button.ion-activated{
  background: transparent !important;
  background-color: transparent !important;
} */

/* -----------------------------------------------------
	CHIPS
----------------------------------------------------- */
.chip {
  padding: 8px 15px;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  margin: 10px 0;
  height: 40px;
  border-radius: 20px;

  ion-icon {
    color: #FFFFFF;
    width: 16px;
  }

  &.chip-primary {
    background: var(--ion-color-primary);
    color: #FFFFFF;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 14px;
    height: 30px;
  }

  &.states {
    padding-left: 5px;
    padding-right: 0;
    cursor: pointer !important;

    ion-label {
      padding: 0 !important;
    }

    &.creating,
    &.shipping,
    &.registered,
    &.activated {
      pointer-events: none;
      /* background: rgba(169, 169, 169, 0.1); */
      color: #9A9A9A;

      ion-icon {
        color: #9A9A9A;
      }
    }

    &.receiving,
    &.received {
      pointer-events: none;
      /* background: rgba(249, 165, 36, 0.1); */
      color: #F9A524;

      ion-icon {
        color: #F9A524;
      }
    }

    &.extraction,
    &.preparation,
    &.sequencing,
    &.analysis,
    &.processing {
      pointer-events: none;
      /* background: rgba(0, 188, 241, 0.1); */
      color: #00BCF1;

      ion-icon {
        color: #00BCF1;
      }
    }

    &.failed {
      pointer-events: none;
      /* background: rgba(239, 78, 76, 0.1); */
      color: var(--ion-color-danger);

      ion-icon {
        color: var(--ion-color-danger);
      }
    }

    &.reported,
    &.completed {
      pointer-events: none;
      color: var(--ion-color-primary);

      ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }

  &.chip-outline {
    background: transparent;
    border: 1px solid;

    &.chip-primary {
      color: var(--ion-color-primary);
    }
  }
}

/* -----------------------------------------------------
	MY PROFILE PAGE
----------------------------------------------------- */
.card.profile {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    min-height: 120px;
    height: 100%;

    .card-content-md,
    .card-content-ios {
      width: 100%;

      ion-row {
        height: 100%;
      }

      img {
        max-width: 180px !important;
        bottom: 0;
        left: 0;
      }
    }

    .card-footer {
      flex-direction: column;

      div {
        height: 100%;
        max-width: 100%;
        text-align: center;
        border-left: 1px solid var(--light-grey);
        padding: 0 15px;

        &:first-of-type {
          border-bottom: 1px solid var(--light-grey);
        }
      }
    }
  }

  .card-content-md,
  .card-content-ios {
    position: relative;
    min-height: 80px;
    max-height: 120px;

    img {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 0;
      max-width: 120px;
      width: 100%;
    }
  }

  &.vet {
    min-height: 150px;
    max-height: 180px;

    .card-content-md,
    .card-content-ios {
      max-height: 180px;
    }

    .card-footer {
      margin-top: 40px;
      flex-direction: row;
      justify-content: start;

      div {
        height: auto;
        text-align: left;
        border: none;
        padding: 0;
        width: auto;

        &:first-of-type {
          border: none;
        }
      }
    }
  }
}

.card.profile-cats {
  .header::part(native) {
    --padding-start: 10px;
  }

  ion-avatar {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-inline-end: 10px;
  }

  ion-item.item-kits {
    margin-left: 53px;
  }

  .card-content-md,
  .card-content-ios {
    padding-top: 0 !important;
  }

  @media (max-width: 767px) {
    ion-item.no-lines-mobile::part(native) {
      --border-width: 0;
    }

    ion-avatar {
      margin-inline: 0px;
    }

    ion-item.item-kits {
      margin-left: 10px;
    }
  }

  &.vet {

    .card-content-md,
    .card-content-ios {
      padding-right: 0;

      &.ion-no-padding {
        padding: 0 !important;
      }

      ion-item {
        &:first-of-type {
          border-top: 1px solid #DDDDDD;
        }

        border-bottom: 1px solid #DDDDDD;
        --detail-icon-font-size: 16px;

        &::part(native) {
          padding-right: 25px;
        }
      }
    }
  }
}

.card.purchase-history {
  .header::part(native) {
    --padding-start: 10px;
  }

  .card-content-md,
  .card-content-ios {
    position: relative;
    min-height: 120px;
    padding-top: 0 !important;

    img {
      position: absolute;
      z-index: 10;
      bottom: 50px;
      right: 15px;
      width: 100%;
      max-width: 120px;

      @media (min-width: 768px) {
        max-width: 160px;
      }
    }
  }

  .button:first-of-type {
    margin-right: 10px;
  }

  .main-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.vet {
    .list-avatar-name {
      padding: 12.5px 0;
    }

    .card-content-md,
    .card-content-ios {
      padding-right: 0;

      ion-item {
        &:first-of-type {
          border-top: 1px solid #DDDDDD;
        }

        border-bottom: 1px solid #DDDDDD;
        --detail-icon-font-size: 16px;

        &::part(native) {
          padding-right: 25px;
        }
      }
    }
  }
}

.card.referrals {
  overflow: visible;
  background: #EF4E4C;

  h2,
  h3 {
    color: #FFFFFF;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    min-height: 120px;
    height: 100%;
    margin-top: 50px;

    .button:first-of-type {
      margin-right: 10px;
    }

    .card-content-md,
    .card-content-ios {
      width: 100%;

      img {
        max-width: 180px !important;
        bottom: 0;
        left: 0;
      }
    }

    .buttons-col {
      text-align: right;
    }
  }

  .card-content-md,
  .card-content-ios {
    position: relative;
    min-height: 80px;

    img {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: -10px;
      max-width: 120px;
      width: 100%;
    }
  }

  &.empty-kits {
    ion-row ion-col:last-of-type {
      text-align: left;
    }

    background:#00BCF1;

    .button {
      --ion-color-light-contrast: #00BCF1;

      &:hover {
        --ion-color-base: #EF4E4C !important;
      }
    }

    .card-content-md,
    .card-content-ios {
      img {
        top: 15px;
        right: 15px;
      }
    }

    @media (min-width: 992px) {
      margin-top: 50px;
      margin-bottom: 40px;

      .button:first-of-type {
        margin-right: 0px;
      }

      .card-content-md,
      .card-content-ios {
        img {
          max-width: 200px !important;
          top: -40px;
          left: 20px;
        }
      }

      ion-row ion-col:last-of-type {
        text-align: right;
      }
    }
  }
}

.card.mycats-empty {
  overflow: visible;

  .card-content-md,
  .card-content-ios {
    position: relative;
    min-height: 120px;

    img {
      position: absolute;
      z-index: 10;
      bottom: -10px;
      right: 15px;
      max-width: 120px;
      width: 100%;
    }
  }

  @media (min-width: 768px) {

    .card-content-md,
    .card-content-ios {
      img {
        right: 25px;
        max-width: 180px;
        bottom: -15px;
      }
    }
  }
}

ion-item.item-no-padding {
  &::part(native) {
    padding-left: 0px;
    --inner-padding-end: 0;
  }
}

ion-item.item-no-padding-left {
  &::part(native) {
    padding-left: 0px;

    @media (max-width: 991px) {
      --inner-padding-end: 0;
    }
  }
}

ion-item.item-no-padding-right {
  &::part(native) {
    --inner-padding-end: 0;

    @media (max-width: 991px) {
      padding-left: 0px;
    }
  }
}

/* -----------------------------------------------------
	MY CAT
----------------------------------------------------- */
.mycat-about-block {
  background: var(--light-grey);
  padding: 20px;
  border-radius: 2px;

  .grid-thumbnail .gallery {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 2px;

    ion-img {
      cursor: pointer;
      width: 100% !important;
      object-fit: cover;
      height: 60px;
    }
  }

  h3 {
    color: var(--black) !important;

    &:last-of-type {
      color: var(--grey);
    }
  }
}

.card.my-cats {
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .card-content-md,
  .card-content-ios {
    padding-left: 0;
    padding-top: 0;

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-top: 15px;
    }
  }

  .cat-reports>div {
    width: 100%;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 3px;
    }

    &.registered {
      background: rgba(169, 169, 169, 0.1);
    }

    &.received {
      background: rgba(249, 165, 36, 0.1);
    }

    &.processing {
      background: rgba(0, 188, 241, 0.1);
    }

    &.failed {
      background: rgba(239, 78, 76, 0.1);
    }

    &.reported,
    &.completed {
      background: rgba(91, 52, 188, 0.1);

      .wrapper {
        display: block;

        hr {
          background: rgba(91, 52, 188, 0.1);
          margin-top: 12px;
        }

        &>div {
          padding: 8px 0;

          .subtitle-s1 {
            color: var(--dark-grey);
            font-size: 12px !important;
            line-height: 14px !important;
          }
        }

        .button ion-label {
          align-items: center;
          display: flex;

          ion-icon {
            margin-right: 5px;
          }
        }

        h5 {
          margin-bottom: 20px;

          @media (max-width: 767px) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  ion-item {
    &.item-lines-full {
      @media (min-width: 768px) {
        --show-full-highlight: 0 !important;
        --border-width: 0 !important;
      }
    }
  }

  ion-avatar {
    width: 90px;
    height: 90px;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .buttons-wrapper {
    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  .detail-wrapper,
  .survey-wrapper,
  .about-wrapper {
    display: block;

    hr {
      margin: 10px 0;
    }

    .button {
      color: var(--dark-grey);

      ion-icon {
        color: var(--dark-grey);
      }

      &:hover ion-icon {
        color: var(--ion-color-primary);
      }

      ion-label {
        display: flex;
        align-items: center;
      }
    }

    @media (min-width: 768px) {
      padding-top: 0;
      display: block;

      &>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      hr {
        margin-top: 5px;
      }
    }
  }

  .col-title-gender {
    align-self: center;
    text-align: left;

    @media (max-width: 767px) {
      text-align: right;
    }

    div {
      justify-content: flex-start;

      .cat-gender {
        margin-left: 30px;
        margin-top: 0;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        justify-content: space-between;

        .cat-gender {
          margin-left: 0px;
        }
      }
    }
  }

  .card-empty {
    padding: 35px;
    border: 1px solid rgba(76, 81, 162, 0.2);
    text-align: center;
    margin: 0;
    margin-top: 20px;
    box-shadow: none;

    &.left {
      margin-right: 15px;
    }

    &.right {
      margin-left: 15px;
    }

    .button {
      margin-top: 20px;
    }

    &.survey {
      padding: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p,
      .button {
        margin: 0;
      }
    }

    @media (max-width: 767px) {
      padding: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.survey {
        padding: 10px;
        margin-top: 20px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }

      &.right,
      &.left {
        margin: 0;
      }

      .button {
        margin-top: 0px;
      }
    }
  }

  &.vet {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;

    ion-item::part(native) {
      --detail-icon-opacity: 1;
    }

    ion-row {
      width: 100%;
      padding: 0;
    }

    .patient-name-owner {
      padding-left: 0;
      padding-right: 0;

      @media (max-width: 767px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .patient-gender-age {
      div {
        justify-content: space-between;
      }

      @media (max-width: 767px) {
        display: none;
        padding-left: 5px;
        padding-right: 5px;

        div {
          justify-content: start;

          h6 {
            margin: 0;

            &:first-of-type {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

ion-col.ion-padding-end,
ion-col.ion-padding-start {
  @media (max-width: 767px) {
    padding-inline-start: 0;
    padding-inline-end: 0;
    --ion-padding: 0
  }
}

/* -----------------------------------------------------
	MY KITS
----------------------------------------------------- */
ion-img.empty-section-image {
  &::part(image) {
    max-height: 140px;

    @media (min-width: 768px) {
      max-height: 160px
    }
  }

  &.large {
    &::part(image) {
      max-height: 320px;

      @media (max-width: 767px) {
        max-height: 200px
      }
    }
  }
}

.card.kits {
  padding: 5px 10px;
  margin-top: 30px;
  margin-bottom: 30px;

  .ion-margin-bottom {
    margin-bottom: 9px !important;
  }

  ion-chip {
    margin-top: 0;
    margin-bottom: 0;
  }

  .kit-name {
    margin-bottom: 0px;
  }

  .report-kit-card {
    margin-bottom: 0;
    margin-right: 5px;
    padding: 4px 5px;
    border-radius: 3px;

    .subtitle-s2 {
      padding-right: 2px !important;
    }

    @media (max-width: 767px) {
      margin-bottom: 5px;
      margin-right: 0px;
    }

    &.activated {
      border: 1px solid var(--light-grey);
      border-radius: 3px;
      margin-left: 5px;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    &.registered {
      background: rgba(169, 169, 169, 0.1);
      padding: 5px 6px;
    }

    &.received {
      background: rgba(249, 165, 36, 0.1);
      padding: 5px 6px;
    }

    &.processing {
      background: rgba(0, 188, 241, 0.1);
      padding: 5px 6px;
    }

    &.failed {
      background: rgba(239, 78, 76, 0.1);
      padding: 5px 6px;
    }

    &.reported,
    &.completed {
      background: rgba(91, 52, 188, 0.1);
      padding: 5px 6px;

      .kit-buttons {
        .button-clear::part(native) {
          padding-inline-start: 8px !important;
          padding-inline-end: 8px !important;
          height: 36px;
        }

        .button-chip {
          margin: 0 !important;
        }

        .button-chip::part(native) {
          padding-inline-start: 10px !important;
          padding-inline-end: 10px !important;
        }

        @media (max-width: 767px) {
          .button-chip::part(native) {
            padding-inline-start: 12px !important;
            padding-inline-end: 12px !important;
          }

          .button-clear::part(native) {
            padding-inline-start: 12px !important;
            padding-inline-end: 12px !important;
          }
        }
      }
    }
  }

  &.vet {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    ion-item::part(native) {
      --detail-icon-opacity: 1;
    }

    ion-row {
      width: 100%;
      padding-right: 5px;
    }

    h3 {
      color: var(--black);
      font-weight: 500;
    }

    .kit-id {
      font-size: 10px;
      line-height: 18px;
      color: var(--dark-grey);
    }

    .chip.states {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;

      ion-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    .pet-owner-wrapper {
      padding-top: 13px;
      padding-bottom: 13px;
    }

    @media (max-width: 767px) {
      .pet-owner-wrapper {
        padding-bottom: 0px;

        ion-row {
          justify-content: space-between;

          ion-col {
            padding-top: 0;
            padding-bottom: 0;

            &:first-of-type {
              padding-left: 0;
              padding-right: 0;
            }

            &:last-of-type {
              text-align: right;
            }
          }
        }
      }

      .card-content-md,
      .card-content-ios {
        padding: 0;

        .report-type-id-wrapper {
          padding: 0;
          margin-top: 10px;

          ion-row {
            justify-content: space-between;

            ion-col {
              padding: 0;

              &:first-of-type {
                text-align: right;
                order: 2;
              }

              &:last-of-type {
                order: 1;
              }
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .report-kit-card {
      margin-bottom: 4px;
      margin-top: 4px;
      border-radius: 3px;

      &.activated {
        border: none !important;
        background: rgba(169, 169, 169, 0.1);
        margin: 0;
        padding: 8px 6px;
      }

      &.registered {
        padding: 8px 6px;
      }

      &.received {
        padding: 8px 6px;
      }

      &.processing {
        padding: 8px 6px;
      }

      &.failed {
        padding: 8px 6px;
      }

      &.reported,
      &.completed {
        padding-top: 3px;
        padding-bottom: 4px;

        .button {
          height: 24px !important;
          min-height: 24px !important;
          font-size: 13px;
        }
      }
    }

    &.resources {
      a {
        h3 {
          position: relative;
          display: inline;
          padding-bottom: 5px;
          margin: 0;
          top: -3px;

          &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            height: 1px;
            background: var(--light-grey);
            width: 100%;
          }
        }

        &:hover,
        &:focus,
        &.active {
          h3 {
            color: var(--ion-color-primary);

            &:before {
              background: var(--ion-color-primary);
            }
          }
        }
      }

      @media (max-width: 767px) {

        .card-content-md,
        .card-content-ios {
          padding: 0 !important;

          p {
            text-align: right;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 15px;

    .card-content-md,
    .card-content-ios {
      padding-top: 5px !important;
      padding-bottom: 15px !important;
    }

    .first-item {
      padding-left: 10px;
      padding-right: 10px;
    }

    .kit-name {
      margin-bottom: 9px;
    }
  }

  @media (min-width: 768px) {

    .card-content-md,
    .card-content-ios {
      padding: 0px !important;
    }

    .item.first-item::part(native) {
      padding: 0px !important;
    }

    .first-item {
      padding-left: 5px;
      padding-right: 15px;

      ion-col:last-of-type {
        text-align: left !important;
      }
    }

    ion-chip {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.vet-kits-overview {
  ion-icon {
    color: #DDDDDD;
    font-size: 16px;
  }

  div:last-of-type {
    min-width: 16px;
  }

  .card {
    height: 100%;
    max-height: 120px;

    .card-content-md,
    .card .card-content-ios {
      padding: 12px 20px;

      h5 {
        color: var(--grey);
      }
    }
  }
}

/* -----------------------------------------------------
	FORMS
----------------------------------------------------- */
.form {
  ion-item {
    --ion-item-border-color: transparent;

    &::part(native) {
      border-radius: 5px;
    }
  }

  ion-input,
  ion-datetime,
  .input-radio,
  ion-textarea {
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    font-size: 15px;
    transition: all .3s ease-in-out;
    width: auto;

    &::part(native) {
      border-radius: 8px;
    }

    ion-icon {
      color: var(--light);
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  ion-item.item-checkbox {
    border: 1px solid var(--light-grey);
    border-radius: 8px;

    &::part(native) {
      padding-left: 10px;
    }
  }

  input {
    min-height: 50px;
    --padding-start: 15px !important;
    --padding-end: 15px !important;
    padding-bottom: 13px;
    padding-top: 13px;

    @media (max-width: 767px) {
      min-height: 45px;
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }

  ion-datetime {
    --padding-start: 15px !important;
    --padding-end: 15px !important;

    @media (max-width: 767px) {
      padding-bottom: 12px !important;
      padding-top: 12px !important;
    }
  }

  .MuiInputBase-input {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    min-height: auto;

    @media (max-width: 767px) {
      padding-bottom: 12px !important;
      padding-top: 12px !important;
    }
  }

  .input-w-icon {
    input {
      padding-right: 40px;
    }

    ion-button {
      position: absolute;
      right: 10px;
    }

    ion-icon {
      max-width: 16px;
    }
  }

  ion-textarea {
    min-height: 120px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;

    textarea {
      padding: 15px 0 !important;
      min-height: 110px;
    }
  }

  .sc-ion-textarea-md-h {
    margin-top: 0px;
  }

  ion-checkbox {
    margin-right: 10px;
    min-width: 18px;
  }

  ion-item ion-checkbox.ios {
    &::part(container) {
      border-radius: 0 !important;
      margin-top: 5px;
    }
  }

  ion-checkbox.ios {
    &::part(container) {
      border-radius: 0 !important;
    }
  }

  .checkbox-selected {
    border: 2px solid var(--secondary);
  }

  .input-radio-wrapper {
    width: 100%;

    @media (min-width: 991px) {
      &:not(:last-child) {
        margin-right: .5em;
      }
    }

    .input-radio {
      display: flex;
      margin-top: 0;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      &::part(native) {
        border-radius: inherit;

        &::after {
          margin-right: -15px;
          @media (min-width: 768px){
            margin-left: -15px;
          }
        }
      }

      &.item-radio-checked {
        border-color: var(--accent);
      }

      @media (max-width: 767px) {
        padding-left: 0px;
        min-height: 45px;
      }
    }

    ion-label {
      font-size: 15px;
      margin: auto 0;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .ios ion-label {
      padding-left: 5px;
    }

    .ios .input-radio {
      padding-left: 0px;
    }
  }

  .input-radio.md::part(native) {
    @media (max-width: 767px) {
      min-height: 45px;
    }
  }

  ion-radio.ios {
    margin: 20px 10px;
    min-width: 20px;

    @media (max-width: 767px) {
      margin: 9px 10px;
    }

    &::part(container) {
      transition: all .3 ease-in-out;
      margin-top: 2px;
    }

    &.radio-checked {
      --color-checked: white;

      &::part(container) {
        background-color: var(--secondary) !important;
        border-color: var(--secondary) !important;
      }
    }
  }

  ion-radio-group {
    ion-radio.ios {
      @media (max-width: 767px) {
        margin-left: 10px;
      }
    }

    .input-radio.ios::part(native) {
      @media (min-width: 768px) {
        --padding-start: 16px !important;
      }

      @media (max-width: 767px) {
        --padding-start: 0px !important;
      }
    }
  }

  .label-stacked.sc-ion-label-ios-h,
  .label-stacked.sc-ion-label-md-h {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
    transform: scale(1);

    &.ion-no-margin {
      margin-bottom: 0 !important;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
    }
  }

  .checkbox-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .item-label-stacked {
    --min-height: inherit;
  }

  ion-radio {
    margin-right: 10px !important;
    margin: 15px 10px 15px 0 !important;

    @media (max-width: 767px) {
      margin: 9px 10px !important;
    }
  }

  ion-radio::part(container) {
    border-color: #9A9A9A;
  }

  .ios ion-radio::part(container) {
    border-color: #9A9A9A;
    border: 2px solid #9A9A9A;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    --padding-start: 10px;
    border-radius: 8px;
  }

  .form-buttons-wrapper {
    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: space-between !important;
    }

    &.between {
      justify-content: space-between !important;
    }
  }

  ion-input[aria-disabled=true],
  ion-textarea[aria-disabled=true] {
    background: repeating-linear-gradient(-45deg,
        #FFF,
        #FFF 10px,
        rgba(240, 242, 247, .35) 10px,
        rgba(240, 242, 247, .35) 20px);

    ion-icon,
    ion-img {
      opacity: .5;
    }

    &::part(native) {
      opacity: .5 !important;
    }
  }

  ion-radio[disabled=true] {
    opacity: .5;

    ion-label {
      opacity: .5;
    }
  }

  .input-radio.item-interactive-disabled {
    opacity: 1 !important;
    background: repeating-linear-gradient(-45deg,
        #FFF,
        #FFF 10px,
        rgba(240, 242, 247, .35) 10px,
        rgba(240, 242, 247, .35) 20px);

    &::part(native) {
      background-color: transparent;
    }
  }
}

.input-radio {
  &::part(native) {
    border-radius: 8px;

    &::after {
      border-radius: 6px;
    }
  }
}

ion-checkbox {
  margin-right: 10px;
}

.item-interactive-disabled {
  ion-input {
    background-color: #EEEEEE;
    color: #A9A9A9;
  }
}

ion-input.with-value {
  background: #F9F9F9;
  font-weight: 500;

  input {
    color: var(--black);
    --placeholder-opacity: 1;
  }
}

ion-select {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 52px;

  &::part(placeholder) {
    --placeholder-color: var(--medium-grey);
    --placeholder-opacity: 1;
  }

  @media (max-width: 767px) {
    min-height: 45px;
  }
}

/* ----------------------------------
          SEARCH BAR
-------------------------------------*/
.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  position: relative;
  &::after{
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    inset: 0;
    border-radius: 30px;
    border: 2px solid var(--ion-color-secondary);
    transition-duration: .3s;
    opacity: 0;
  }
  &.searchbar-has-focus{
    &::after{
      opacity: 1;
    }
  }
  .searchbar-input{
    &.sc-ion-searchbar-md,
    &.sc-ion-searchbar-ios {
      border: 1px solid #DDDDDD;
      padding-top: 9px;
      padding-bottom: 9px;
      box-shadow: none;
      font-size: 15px;
      line-height: 24px;
      text-align: left;
      border-radius: 30px;
      padding-inline-start: 45px;
      padding-inline-end: 40px;
      transition: border .3s ease-in-out;
      background: #fff;
      max-height: 40px;
    }
  }

  .searchbar-search-icon{
    &.sc-ion-searchbar-md,
    &.sc-ion-searchbar-ios {
      color: var(--light);
    }

    &.sc-ion-searchbar-md {
      top: 10px !important;
    }
  
    &.sc-ion-searchbar-ios {
      top: 0px !important;
      width: 18px;
      left: 8px;
    }
  }

  .searchbar-clear-button.sc-ion-searchbar-md {
    color: var(--grey);
  }
}


.alert-error-message {
  background-color: rgba(239, 78, 76, 0.1);
  padding: 13px 20px;

  ion-label {
    display: flex;
    align-items: center;
    color: var(--ion-color-danger);

    ion-icon {
      margin-right: 5px;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

// Input Error message
.ion-text-wrap {
  display: block !important;
}

ion-item.input-error-message {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: var(--dark);
  width: 100%;

  .sc-ion-label-md-h,
  .sc-ion-label-ios-h {
    margin-top: 0;

    ion-icon {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  .input-error-label {
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 5px;
      font-size: 18px;
    }
  }
}

.form-addcat {
  .image-box-wrapper {
    position: relative;
    margin: auto;
    width: 150px;
    height: 150px;

    .form-image-button {
      border-radius: 50%;
      border: 5px solid white;
      height: 50px;
      width: 50px;
      background: #4C51A2;
      border: 3px solid #FFFFFF;
      margin: auto;
      position: absolute;
      left: 100px;
      top: 100px;

      ion-icon {
        color: #FFFFFF;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }

    &.thumbnail {
      width: 65px;
      height: 65px;

      .form-image-button {
        border: 5px solid white;
        height: 30px;
        width: 30px;
        background: #9A9A9A;
        border: 3px solid #FFFFFF;
        margin: auto;
        position: absolute;
        top: 50px;
        left: calc(50% - 16px);

        ion-icon {
          color: #FFFFFF;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
}

.MuiInputBase-input {
  font-family: 'Jakarta' !important;
  font-size: 16px !important;

  @media (max-width: 767px) {
    font-size: 14px !important;
  }
}

.MuiFormControl-root {
  margin-top: 0;
  width: 100%;

  .MuiInputBase-root {
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    transition: all .3s ease-in-out;
    min-height: 50px;

    @media (max-width: 767px) {
      min-height: 45px;
    }

    &::after {
      display: none;
    }
  }
}

.MuiInput-underline:before {
  display: none;
}

.MuiFormHelperText-root {
  font-size: 12px;
  line-height: 14px;
  color: var(--black);
}

.MuiPickersToolbar-toolbar {
  background-color: var(--ion-color-primary) !important;
}

.MuiButton-root,
.MuiTypography-subtitle1,
.MuiTypography-root {
  font-family: 'Jakarta' !important;
}

.MuiPickersDay-current .MuiIconButton-label p {
  color: #FFFFFF !important;
}

/* Auto fill browsers */
input:-webkit-autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable {
  background-color: var(--light-grey);
  color: var(--black);
}

input:-internal-autofill-selected {
  background-color: var(--light-grey) !important;
  color: var(--black) !important;
}

input {
  -webkit-text-fill-color: var(--black) !important;
}

.register-point-contact {
  justify-content: space-between;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.card.form-card {
  padding: 30px 0 70px;

  .form {
    ion-item.input-error-message {
      margin: 0;
    }

    ion-item::part(native) {
      --inner-padding-end: 0;
      --inner-padding-start: 0;
      padding: 0;
    }

    hr {
      margin: 50px 0;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    margin-inline: 0;
  }
}


/* -----------------------------------------------------
	HEADERS
----------------------------------------------------- */
.navbar-toggler::part(native) {
  padding-right: 15px !important;
}

.header-md::after,
.header-ios::after,
ion-header::after {
  background: var(--light-grey);
  height: 1px;
}

.header-md.header-navbar::after,
.header-ios.header-navbar::after,
ion-header.header-navbar::after {
  background: none;
  height: 0;
}

.header-navbar {
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1)); */
  z-index: 1050;
  position: fixed;
  transition: opacity .1s ease-in-out;
  opacity: 1;
  background-color: #FFFFFF;

  &.hide-navbar {
    visibility: hidden;
    opacity: 0;
  }
}

.ios.toolbar-title-default {
  padding-bottom: 10px;

  .title-default {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    color: var(--black);
    font-weight: 500;

    &.with-back-button {
      padding-left: 50px;
      padding-right: 50px;
      text-align: center;
    }
  }
}

.toolbar-title {
  text-overflow: inherit;
  white-space: inherit;
}

.toolbar-title-default ion-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-toolbar-title {
  .toolbar-title-default {
    --border-color: transparent;
    --ion-toolbar-border-color: transparent;
  }

  .header-md::after,
  .header-ios::after,
  ion-content ion-header::after {
    background: transparent;
  }
}

.button.button-popover {
  margin: 0;

  &::part(native) {
    overflow: inherit;
    padding: 5px;
    background: #F3F5F0 !important;
    --background: #F3F5F0 !important;
    --ion-color-light: #F3F5F0 !important;
    border-radius: 35px;
  }

  ion-avatar {
    width: 30px;
    height: 30px;
    margin-inline-end: 10px;

    .placeholder-avatar {
      background: var(--ion-color-secondary);
      width: 100%;
      height: 100%;
      border-radius: 35px;

      h5 {
        color: #FFFFFF;
        text-align: center;
        padding-top: 5px;
      }
    }
  }

  ion-item {
    &::part(native) {
      --padding-start: 0;
      --background: transparent;
    }

    &::part(detail-icon) {
      transform: rotate(90deg);
      --detail-icon-font-size: 14px;
      color: #676767;
      opacity: 1;
    }
  }
}

.header-popover {
  --backdrop-opacity: 0;
  --box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  --width: 420px;
  top: 20px;
  padding: 0;

  .popover-content {
    position: relative !important;
    top: calc(-50vh + 180px) !important;
    left: calc(50% + 162px) !important;

    @media (max-width: 1220px) {
      left: calc(50% + 50px) !important;
    }
  }

  &.common_user {
    .popover-content {
      top: calc(-50vh + 175px) !important;
    }
  }

  .content {
    padding: 23px 30px !important;
    margin: 0;
  }
}

.workspaces {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  transition: border .15s ease-in-out;

  h5 {
    color: var(--black);

    &:hover,
    &:focus,
    &:active {
      color: var(--black);
    }
  }

  ion-avatar {
    margin-right: 10px;
  }

  &.active {
    border: 2px solid var(--ion-color-primary);
  }
}

hr {
  margin: 0;
}

a.logout {
  align-items: center;
  display: flex;

  ion-icon {
    margin-left: 5px;
  }

  p span {
    font-weight: 500 !important;
  }
}

.no_desktop {
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 991px) {
    display: block;
  }
}

.no_mobile {
  @media (min-width: 992px) {
    display: block;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.workspaces_wrapper.no_desktop {
  margin: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  width: calc(100% - 20px);
}

.nav-item.no_desktop {
  text-align: end;
  margin: 8px 24px 0 0;

  .logout {
    padding-top: 18.5px;
    padding-bottom: 18.5px;
    height: auto;
    width: auto;
    margin-right: 10px !important;
    margin-top: 0;
    margin-bottom: 0;

    &::part(native) {
      overflow: visible;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/* -----------------------------------------------------
	LIGHTBOX/ GALLERY IMAGES
----------------------------------------------------- */
.fslightbox-container {
  font-family: 'Jakarta';
}

/* -----------------------------------------------------
	404
----------------------------------------------------- */
.page-404 {
  .toolbar-title-default {
    display: none;
  }

  .content {
    margin-top: 65px;

    @media (max-width: 767px) {
      margin-top: 48px;
    }

    ion-img.no-margin-top {
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }
}

/* -----------------------------------------------------
	CONFIRM PAGE
----------------------------------------------------- */
.confirm-page {
  ion-img.empty-section-image::part(image) {
    max-height: 200px;

    @media (max-width: 767px) {
      max-height: 180px;
    }
  }
}

/* -----------------------------------------------------
	MODALS
----------------------------------------------------- */
ion-modal.modal-share-report {
  ion-header {
    padding: 5px 15px 0px 20px;
    display: flex;
    align-items: center;

    ion-title {
      padding: 0;
      position: relative;
      text-align: left;
      color: var(--black);
    }

    ion-button {
      margin-right: auto;
      color: var(--ion-color-dark) !important;

      &::part(native) {
        padding: 0;
      }

      ion-icon {
        font-size: 24px;
        color: var(--grey);
      }
    }
  }

  .form {
    padding: 0 10px 0 20px;

    ion-item::part(native) {
      padding: 0;
    }
  }

  .button-wrapper {
    padding: 0;
    text-align: center;
  }

  .modal-wrapper {
    max-height: 400px;

    ion-content {
      overflow: auto;
    }
  }

  @media (max-width: 767px) {
    .modal-wrapper {
      margin: auto 15px;
      max-height: 350px;
    }

    .sc-ion-modal-md-h,
    .sc-ion-modal-ios-h {
      --max-height: 350px;
      --height: 350px;
    }
  }

  &.vet {
    .modal-wrapper {
      max-height: 490px;

      @media (max-width: 767px) {
        max-height: 410px;
      }
    }
  }

  &.short {
    .modal-wrapper {
      max-height: 250px;

      @media (max-width: 767px) {
        max-height: 220px;
      }
    }
  }

  &.modal-confirmation .modal-wrapper {
    max-height: 340px;

    @media (max-width: 767px) {
      max-height: 300px;
    }
  }
}

ion-modal.modal-right {
  --height: 100%;
  --width: 400px;
  --border-radius: 0;

  @media (max-width: 767px) {
    --width: 360px;
  }

  .modal-wrapper {
    margin-left: auto;
    max-height: 100vh;
  }

  ion-title.ios {
    text-align: left !important;
    margin-top: 45px !important;
  }

  &.ios .modal-content {
    margin-top: 10px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  @media (max-width: 1199px) {
    ion-header.md {
      margin-top: 20px;
    }

    ion-header.ios {
      margin-bottom: 45px;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {
    ion-header {
      margin-top: 50px;
    }

    ion-header.ios {
      margin-top: 50px;
    }
  }
}

/* Fix submit button style */
[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button-bevel;
}

/* -----------------------------------------------------
	FINDER
----------------------------------------------------- */
.finder {
  padding: 5px 10px 5px;

  hr {
    width: 100%;
    margin: 4px 0 0 0;
  }

  ion-col:last-of-type {
    text-align: end;
  }

  &.kits {
    hr {
      margin: 30px 0 18px 0;
    }
  }
}

/* -----------------------------------------------------
	VETS
----------------------------------------------------- */
.empty-state-profile-vet {
  padding: 40px 0;

  .empty-section-image {
    margin-bottom: 10px;

    img {
      max-height: 120px;
    }
  }
}

.success-section-circle {
  display: block;
  background: rgba(91, 185, 71, 0.1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: relative;
  margin: 0 auto;

  ion-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.big {
    width: 90px;
    height: 90px;
  }
}

.page-no-margin {
  .content {
    margin-top: 0;
  }
}

.content {
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
  }
}

.resources-search-input .searchbar-input {
  border-radius: 35px !important;
}


/* -----------------------------------------------------
	TEAM
----------------------------------------------------- */
.card.team {
  h3 {
    color: var(--dark-grey);
  }

  ion-label {
    display: flex;
    align-items: center;

    ion-icon {
      min-width: 14px;
      font-size: 14px;
    }

    @media (max-width: 767px) {
      padding: 10px 0 5px;
    }
  }

  .button-wrapper {
    text-align: right;
  }

  @media (max-width: 767px) {
    .button {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0;
    }

    .button-wrapper {
      text-align: left;
    }
  }
}

.card.team-contributors {
  margin-top: 50px;
  padding: 60px 0;
  border-radius: 5px;
  border-left: 5px solid var(--ion-color-secondary);

  h2 {
    color: var(--ion-color-secondary);
  }

  p {
    margin-bottom: 0;
  }

  ion-item {
    margin-bottom: 5px;

    &::part(native) {
      padding: 0;
    }

    ion-input {
      border: 1px solid var(--light-grey);
      border-radius: 45px;
      --padding-start: 10px;
      margin-right: 0;
      --padding-end: 56px;
      min-width: calc(100% - 80px);

      ion-icon {
        margin-left: 16px;
      }
    }

    .button {
      margin: 0;
      margin-left: -40px;
      z-index: 100;
      height: 39px;
    }
  }

  @media (max-width: 767px) {
    padding: 15px;
    margin-top: 30px;

    ion-item {
      ion-input {
        min-width: calc(100% - 54px);
        font-size: 14px;
      }
    }
  }
}

.card.kits.resources.vet.team {
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}


/* -----------------------------------------------------
	PATIENTS DETAIL
----------------------------------------------------- */
.patient-detail-header {
  padding-left: 10%;

  .cat-name,
  .cat-gender {
    padding-right: 30px;
    margin: 0;
  }

  @media (max-width: 991px) {
    padding-left: 7%;
  }

  @media (max-width: 767px) {
    margin-left: 40px;
    width: calc(100% - 40px);
    padding-left: 0;

    .cat-gender {
      display: none;
    }

    &.md {
      margin-left: 0px;
      width: 100%;
      padding: 0;
    }
  }
}

.card.kits.vet.patient-detail {
  margin-inline: 0;

  .cat-reports {
    margin-bottom: 10px;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 3px;
    }

    .chip.states ion-label {
      font-size: 16px;
      line-height: 20px;
      text-transform: inherit;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.registered,
    &.activated {
      &::part(native) {
        --background: rgba(169, 169, 169, 0.1) !important;
      }
    }

    &.received {
      &::part(native) {
        --background: rgba(249, 165, 36, 0.1) !important;
      }
    }

    &.processing {
      &::part(native) {
        --background: rgba(0, 188, 241, 0.1) !important;
      }
    }

    &.failed {
      &::part(native) {
        --background: rgba(239, 78, 76, 0.1) !important;
      }
    }
  }

  .expanded {
    background: rgba(91, 52, 188, 0.1) !important;
    margin-bottom: 10px;
    padding: 15px;

    hr {
      background: rgba(91, 52, 188, 0.1);
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .button ion-label {
      align-items: center;
      display: flex;
    }
  }
}

.patient-detail.subtitle-s1 {
  @media (max-width: 767px) {
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.team-contributors {
  .button-invite {
    &.ion-color-secondary {
      &:hover {
        &::part(native) {
          --ion-color-base: var(--ion-color-primary);
        }
      }
    }

    &.syncing {
      &::part(native) {
        min-width: 106.36px;
        background: #EFEBF9;
        color: var(--ion-color-primary);
        border: 1px solid var(--ion-color-primary);
      }
    }

    &.sent {
      &::part(native) {
        min-width: 106.36px;
        background: #EFF8ED;
        color: var(--ion-color-success);
        border: 1px solid var(--ion-color-success);
      }
    }
  }
}




/* -----------------------------------------------------
	CARROUSEL
----------------------------------------------------- */

.slick-arrow {
  width: 40px !important;
  height: 40px !important;
  transition: opacity .15s ease-in-out;

  @media (max-width: 767px) {
    &::before {
      width: 30px !important;
      height: 30px !important;
    }
  }

  &.slick-disabled {
    opacity: 0;
    cursor: none;
  }

  &::before {
    margin: auto;
    width: 40px;
    height: 40px;
    z-index: 1000;
    font-size: 1px !important;
    opacity: 1 !important;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../theme/basepaws-carrousel-arrow.svg');
    background-color: white;
    border-radius: 50%;
    border: 3px solid white;
    position: absolute;
  }

  &.slick-prev {
    z-index: 10;
    left: 0 !important;

    &::before {
      top: 0%;
      left: 0%;
      transform: rotate(180deg) translate(0, 0);
    }
  }

  &.slick-next {
    right: 0px;
    z-index: 10;

    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.slick-slide {
  padding: 0 10px;
}

@media (max-width: 767px) {
  slick-list {
    padding: 0 !important;
  }
}

.slick-track {
  margin-left: 0 !important;
}



/* -----------------------------------------------------
	NEW STYLESHEET
----------------------------------------------------- */

/* ----- Displays, Headings and paragraphs ------- */
.Heading_1,
.Heading_2,
.Heading_3,
.Heading_4,
.Heading_5,
.Heading_6,
.Body_bold,
.Body_small_bold,
.Running_head {
  font-weight: 800 !important;
}

.Lead {
  font-weight: 300 !important;
}

.Body_regular,
.Body_small_regular {
  font-weight: 400 !important;
}

.Heading_1 {
  font-size: 36px;
  line-height: 45px;

  @media (max-width: 767px) {
    font-size: 27px;
    line-height: 36px;
  }
}

.Heading_2 {
  font-size: 30px;
  line-height: 39px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
}

.Heading_3 {
  font-size: 24px;
  line-height: 33px;

  @media (max-width: 767px) {
    font-size: 21px;
    line-height: 30px;
  }
}

.Heading_4 {
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 27px;
  }
}

.Heading_5 {
  font-size: 18px;
  line-height: 27px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.Heading_6 {
  font-size: 16px;
  line-height: 25px;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 23px;
  }
}

.Running_head {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

.Lead {
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.Body_regular,
.Body_bold {
  font-size: 15px;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
  }
}

.Body_small_regular,
.Body_small_bold {
  font-size: 13px;
  line-height: 21px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
}


/* ------------------------------
          TEXT COLORS
------------------------------- */
.text_primary {
  color: var(--primary) !important;
}

.text_secondary {
  color: var(--secondary) !important;
}

.text_grey {
  color: var(--grey) !important;
}

.text_accent {
  color: var(--accent) !important;
}

.text_grey {
  color: var(--grey) !important;
}

.text_light {
  color: var(--light) !important;
}

.text_dark {
  color: var(--dark) !important;
}

.text_danger {
  color: var(--alert) !important;
}


.alert_message {
  padding: 6px 12px;
  margin: 18px 0 5px;

  p {
    margin: 0;
  }

  &.bg_grey {
    background-color: #F3F5F0;
  }

  &.alert_form {
    padding: 15px;
    border: 1px solid;
    border-radius: 5px;

    .input-error-label {
      margin-bottom: 10px;
      display: flex;

      ion-icon {
        margin-right: 5px;
      }
    }

    p {
      margin-left: 20px;
    }

    a {
      font-weight: 700;
    }
  }

  &.danger {
    background-color: rgba(239, 78, 76, 0.05);
    border-color: var(--ion-color-danger);
  }

  &.warning {
    background-color: rgba(249, 165, 26, 0.05);
    border-color: var(--ion-color-warning);
  }

  &.no-margin {
    margin: 0px;
  }
}

.anchor {
  height: 80px;
  margin-top: -80px;
}


/* -----------------------------
    Buttons (new stylesshet)
------------------------------ */
ion-button.big_button {
  --height: 55px;
  min-height: 55px;
  font-size: 15px;

  &::part(native) {
    padding-right: 35px;
    padding-left: 35px;
    border: 1px solid var(--ion-color-base);
  }

  @media (max-width: 767px) {
    --height: 42px;
    min-height: 42px;
    font-size: 16px;

    &::part(native) {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
}

ion-button.default_button {
  --height: 40px;
  min-height: 40px;
  font-size: 15px;

  &::part(native) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (max-width: 767px) {
    --height: 30px;
    min-height: 30px;
    font-size: 14px;

    &::part(native) {
      padding-right: 18px;
      padding-left: 18px;
    }
  }
}

ion-button.big_button.button-solid,
ion-button.default_button.button-solid {
  &::part(native) {
    border: 1px solid var(--ion-color-base);
  }
}

ion-button.done_button {
  --ion-color-primary: rgba(91, 185, 71, 0.1);
  color: var(--ion-color-success) !important;

  &::part(native) {
    color: var(--ion-color-success) !important;
  }

  &:hover {
    --ion-color-primary: rgba(91, 185, 71, 0.2) !important;

    &::part(native) {
      --ion-color-primary: rgba(91, 185, 71, 0.2) !important;
    }
  }

  ion-icon {
    color: var(--ion-color-success);
  }

  &.left {
    margin-left: -12px;
  }
}

ion-button.loader_button {
  ion-img {
    max-width: 40px;
  }
}

.loader {
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0AviewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
  ;
}

/* -----------------------------
    Buttons -focus state-
------------------------------ */
ion-button.ion-color-primary:not(.button-clear, .button-outline) {
  &:focus {
    &::part(native) {
      color: white;
      border-color: white;
      box-shadow: none;
      background-color: var(--secondary);
    }
  }
}

ion-button.ion-color-light:not(.button-clear) {
  border-color: var(--ion-color-primary);

  &:focus {
    &::part(native) {
      border-color: var(--ion-color-primary);
      box-shadow: 0px 0px 5px white;
    }
  }
}

ion-button.button-clear {
  &:focus {
    &::part(native) {
      color: var(--ion-color-base);
      box-shadow: none;
    }
  }

  &.ion-color-primary:focus {
    &::part(native) {
      color: var(--secondary);
    }
  }

  &.ion-color-dark:focus {
    &::part(native) {
      color: var(--secondary);
    }
  }
}

ion-button.button-clear.no-padding {
  &::part(native) {
    padding: 0 !important;
  }
}

ion-button.button-outline:not(.filter_button) {
  &:focus {
    &::part(native) {
      border-color: var(--secondary);
      color: var(--secondary);
      box-shadow: none;
    }

    ion-icon {
      color: var(--secondary);
    }
  }
}

/* -----------------------------
    Buttons -disable state-
------------------------------ */
ion-button.button-disabled {
  opacity: 0.3;
}

/* -----------------------------
    Buttons -activated state-
------------------------------ */

/* -----------------------------
    Inputs -focus state-
------------------------------ */

ion-item {
  outline: none !important;
  &::part(native) {
    border-radius: 8px;
    &::after {
      border-radius: 8px;
      pointer-events: none;
      transition: all .3s ease-in-out;
      border: 2px solid var(--ion-color-secondary);
    }
  }
  &.item-has-focus,
  &.react-datepicker-ignore-onclickoutside {
    &::part(native) {
      opacity: 1;
      &::after {
        opacity: 1;
      }
    }
  }
  &.item-input,
  &.item-textarea,
  &.item-datetime {
    &::part(native) {
      border-radius: 8px;
      &::after {
        background-color: transparent!important;
      }
    }
  }
}
ion-input,
ion-textarea,
ion-datetime {
  &::after {
    content: "";
    opacity: 0;
    z-index: 1;
    inset: -1px;
    position: absolute;
    border-radius: 8px;
    border: 2px solid var(--ion-color-secondary);
    transition: all .3s ease-in-out;
    background-color: transparent!important;
    pointer-events: none;
  }
  /* Focus state */
  &:focus,
  &:focus-within,
  &:focus-visible,
  &.has-focus {
    outline: none !important;
    border-color: transparent;
    &:after {
      opacity: 1;
    }
  }
  /* Error state */
  &.has-error {
    border-color: transparent;
    background-color: #FFF0F0;
    &:after {
      opacity: 1;
      border-color: var(--ion-color-danger);
    }
    &.has-focus {
      background-color: transparent;
    }
  }
}

.MuiFormControl-root .MuiInputBase-root {
  &.Mui-focused {
    border: 2px solid var(--ion-color-secondary);
    box-shadow: 0px 0px 4px var(--ion-color-secondary);
  }
}

div.has-error {
  color: var(--ion-color-danger);
}

.MuiFormControl-root .MuiInputBase-root {
  &.Mui-error {
    border: 2px solid var(--ion-color-danger);
    box-shadow: 0px 0px 4px var(--ion-color-danger);
  }
}

@media (max-width: 991px) {
  .order-mobile-2 {
    order: 2;
  }

  .order-mobile-1 {
    order: 1;
  }
}

// Fix style for card footer with buttons on Mobile - TEST
.footer_fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background: #FFF;
  box-shadow: 1px 2px 10px rgba(238, 238, 238, 0.8);
}

.ios .footer_fixed {
  margin-left: -25px;
  margin-right: -25px;
  z-index: 1000;
}

ion-item {
  --detail-icon-color: var(--medium-grey);
}

ion-label {
  white-space: pre-wrap !important;

  h4 {
    white-space: nowrap;
  }
}

.nav_item {
  a.active {
    color: var(--sad-purple);

    &:after {
      width: 100%;
      opacity: 0.25;
    }
  }
}

.button_circle {
  margin-left: 5px;

  ion-icon {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: #FFF;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      z-index: -1
    }

    &.updating {
      /* opacity: .5; */
      animation: loading .8s infinite;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.w_topbar {
  ion-toolbar {
    margin-top: 120px !important;

    @media (max-width: 767px) {
      margin-top: 110px !important;
    }
  }
}

*::selection {
  background-color: rgba(91, 52, 188, 0.1);
}

.sc-ion-input-md-h,
.sc-ion-input-ios-h,
ion-input,
.sc-ion-textarea-md-h,
.sc-ion-textarea-ios-h,
ion-textarea {
  /*  --placeholder-color: var(--light-grey); */
  --placeholder-opacity: .5;
}

.search-sort-fullscreen {
  margin: -20px;
  margin-top: -10px !important;
  border-top: 1px solid var(--light-grey);

  ion-col {
    padding: 0;
  }

  ion-card {
    margin: 0 !important;
    box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.05) !important;
    z-index: 999;
    overflow: visible;

    @media (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;

      ion-searchbar {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

/* Pagination */
ul.pagination_numbers {
  padding: 0 20px;

  li {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    color: var(--grey);
    width: auto;
    min-width: 36px;
    min-height: 36px;
    background-color: transparent;
    padding: 6px 13px;

    &.active {
      color: white;
      background-color: var(--primary);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.slider-arrow-white {
  .slick-list {
    overflow-x: hidden;
    overflow-y: visible;
    height: calc(100% + 40px);
  }

  .slick-arrow {
    width: 65px !important;
    height: 65px !important;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.05));
    --ion-color-base: var(--ion-color-light) !important;
    --ion-color-secondary: var(--ion-color-light) !important;
    transition: filter .3s ease-in-out;

    @media (max-width: 767px) {
      width: 40px !important;
      height: 40px !important;
      filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.1));
    }

    &:hover {
      filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.1));
    }

    ion-icon {
      font-size: 24px;
      min-width: 24px;

      @media (max-width: 767px) {
        font-size: 20px;
        min-width: 20px;
      }
    }

    &::before {
      display: none;
    }

    &.slick-next {
      right: -85px !important;
      left: auto;

      @media (max-width: 767px) {
        top: -30px;
        right: 0 !important;
      }
    }

    &.slick-prev {
      left: -85px !important;
      right: auto;

      @media (max-width: 767px) {
        top: -30px;
        right: 60px !important;
        left: auto !important;
      }
    }
  }
}


.select_filter {
  .select-search__select {
    right: auto !important;
  }
}


.col-no-padding {
  padding: 0 !important;
}


ion-popover.tooltip {
  --width: auto;

  .popover-content {
    --background: var(--primary) !important;
    color: #FFF !important;
    padding: 5px 10px !important;
  }
}

.check_filter_button.item-checkbox-checked {
  border-color: var(--accent);

  ion-label {
    color: var(--accent) !important;
  }
}

/* filter */
li:has(>ion-item.void) {
  display: none;
}


.border-0 {
  border: none !important;
}


/* ----------------------
          Alert  
------------------------- */
.alert{
  --max-width: 540px;
  font-family: 'Jakarta';
  .alert-head{
    padding: 60px 60px 10px;
    text-align: center;
    .alert-title{
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      color: var(--primary);
    }
  }
  .alert-message{
    padding: 0px 60px;
    text-align: center;
    font-size: 15px;
    line-height: 24px;
    color: var(--grey);
  }
  .alert-button-group{
    padding: 20px 60px 60px;
    justify-content: center;
  }
  .alert-button{
    border-radius: 20px;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    text-transform: none;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .alert-button-role-cancel{
    border: 1px solid var(--grey);
    color: var(--grey);
  }
  .alert-button-role-confirm{
    background-color: var(--alert);
    color: var(--white);
  }
  @media (max-width: 767px) {
    --max-width: 280px;
  }
}