.card_grey_banner{
  background: var(--accent-light);
  border-left: 4px solid var(--accent);
  border-radius: 5px;
  padding: 17px 30px;
  margin: 0 !important;
  box-shadow: none !important;
  @media (max-width: 767px) {
    padding: 20px;
    margin: 0 5px !important;
  }
  &.small{
    border-left: none;
  }
}

.text_md_right{
  text-align: right;
  @media (max-width: 767px) {
    text-align: left;
  }
}