ion-card-header{
  padding: 0;
}
.card_footer{
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 10px 0;
}
.card_content{
  padding: 0 0 25px;
  ion-col{
    padding: 0 !important;
  }
}
.social_media_wrapper{
  ion-button{
    margin-bottom: 10px;
  }
  hr{
    flex-grow: .3;
    background-color: var(--light-grey);
    @media (max-width: 767px) {
      flex-grow: 1;
    }
    @media (max-width: 550px) {
      flex-grow: .3;
    }
  }
  p{
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 60px);
    text-align: center;
  }
}
.divider{
  margin: 25px 0;
  width: 98%;
}

@media (max-width: 767px) {
  .card_content{
    padding: 0;
  }
}