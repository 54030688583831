
/* -----------------------------------------------------
	TOAST
----------------------------------------------------- */
.toast{
  --background: var(--primary);
  color: #FFFFFF;
  --border-radius: 0px;
  --ion-safe-area-top: 90px;
  --ion-safe-area-bottom: 30px;
  --box-shadow: 1px 2px 10px rgba(238, 238, 238, 0.8);
  --max-width: 360px;
  --end: 0;
  --start: calc(100% - 430px);
  
  &::part(container){
    text-align: left;
    padding: 5px;
    padding-left: 10px;
  }
  &::part(header){
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
    color: #FFFFFF;
    position: relative;
    padding-left: 35px;
    &::after{
      position: absolute;
      content:"";
      left: 0;
      top: 2px;
      width: 24px;
      height: 24px;
      background-image: url(../../../../theme/basepaws-icon-toast-success.svg);
      background-position: center;
      background-size: cover;
    }
  }
  &::part(message){
    font-size: 15px;
    line-height: 24px;
    color: var(--light-grey);
  }
  @media (max-width: 767px) {
    --max-width: 330px;
    --start: calc(100% - 400px);
    --ion-safe-area-bottom: 20px;
    --ion-safe-area-top: 65px;
  }
  @media (max-width: 440px) {
    --min-width: 300px;
    --max-width: 380px;
    --start: 10px;
    --end: 10px;
    &::part(header){
      font-size: 16px;
      line-height: 24px;
    }
    &::part(message){
      font-size: 14px;
      line-height: 21px;
    }
  }
}