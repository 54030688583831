/* -----------------------------------------------------
	NAVBAR POPOVER
----------------------------------------------------- */

.content{
  padding: 0px 10px 20px;
  margin: 0;
  &:last-child{
    padding-bottom: 0;
  }
  &.nav_links{
    padding: 10px;
    &:last-child{
      padding: 10px 10px 0;
    }
    ion-button{
      --ion-color-primary: var(--sad-purple)
    }
  }
}
.workspaces{
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  transition: border .15s ease-in-out;
  h5{
    color: var(--black);
    &:hover,
    &:focus,
    &:active{
      color: var(--black);
    }
  }
  ion-avatar{
    margin-right: 10px;
  }
  &.active{
    border: 2px solid var(--ion-color-primary);
  }
  }
  hr{
    margin: 0;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  a.logout{
    align-items: center;
    display: flex;
    ion-icon{
      margin-left: 5px;
    }
  p span{
    font-weight: 500 !important;
  }
}
.org_data{
  margin-bottom: 16px;
  margin-left: 15px;
  @media (max-width: 991px) {
    margin-top: 30px !important;
    margin-left: 0;
    border-bottom: 1px solid var(--light-grey);
    padding-bottom: 16px;
    margin-bottom: 0;
  }
}