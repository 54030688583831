.banner_actions{
  box-shadow: none;
  border: 1px solid rgba(62, 64, 84, 0.1);
  border-radius: 5px;
  padding: 20px;
  @media (max-width: 991px) {
    margin-top: 1em;
    margin-left: 5px;
    margin-right: 5px;
  }
  ion-button{
    --height: 21px;
    min-height: 21px;
    max-height: 21px !important;
    margin: 0;
    &::part(native){
      --height: 21px;
      max-height: 21px;
    }
  }
  .icon_wrapper{
    display: block;
    background: rgba(62, 64, 84, .1);
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-right: 20px;
    ion-icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: var(--primary);
    }
    @media (max-width: 767px) {
      margin-right: 15px;
      ion-icon{
        font-size: 15px;
      }
    }
  }
}

.text_md_right{
  text-align: right;
  @media (max-width: 991px) {
    text-align: left;
  }
}

.m_sm_0{
  @media (max-width: 991px) {
    margin: 0 !important;
  }
}

.banner_line{
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  background: var(--accent-light);
  border-radius: 5px;
  border-left: 4px solid var(--accent);
  padding: 20px 30px;
  @media (max-width: 991px) {
    padding: 20px;
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    ion-button{
      margin-top: 1em;
    }
  }
}

.mt_sm_4{
  @media (max-width: 991px) {
    margin-top: 2em !important;
  }
}