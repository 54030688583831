/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: var(--accent);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--accent);
  --ion-color-primary-tint: var(--accent);
  /** secondary **/
  --ion-color-secondary: var(--secondary);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--secondary);
  --ion-color-secondary-tint: var(--secondary);
  /** tertiary **/
  --ion-color-tertiary: var(--primary);
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: var(--primary);
  --ion-color-tertiary-tint: var(--primary);
  /** success **/
  --ion-color-success: var(--success);
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: var(--success);
  --ion-color-success-tint: var(--success);
  /** warning **/
  --ion-color-warning: var(--warning);
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: var(--warning);
  --ion-color-warning-tint: var(--warning);
  /** danger **/
  --ion-color-danger: var(--alert);
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade:  var(--alert);
  --ion-color-danger-tint:  var(--alert);
  /** dark **/
  --ion-color-dark: var(--grey);
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: var(--grey);
  --ion-color-dark-tint: var(--grey);
  /** medium **/
  --ion-color-medium: var(--light);
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: var(--light);
  --ion-color-medium-tint: var(--light);
  /** light **/
  --ion-color-light: var(--white);
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: var(--accent);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-font-family: 'Jakarta';

  --ripple-color: transparent;

  /* New Grey scale for text */
 /*  --black: #25292E;
  --dark-grey: #676767;
  --grey: #878787;
  --medium-grey: #A9A9A9;
  --light-grey: #EEEEEE;
 */
  /* New Color scale */
/*   --bright-purple: #5B34BC;
  --sad-purple: #484366;
  --purple: #4C51A2;
  --light-blue: #00BCF1;

  --ion-color-warning: #F9A524;
  --ion-color-success: #5BB947;
  --ion-color-danger: #EF4E4C; */

  /* BRAND COLORS */
  --primary: #3E4054;
  --secondary: #4C529F;
  --accent: #4574C1;
  --primary-light: rgba(62, 64, 84, 0.1);
  --secondary-light: rgba(76, 82, 159, 0.08);
  --accent-light: rgba(69, 116, 193, 0.06);
  --dark: #2D2D2D;
  --grey: #5D5D5D;
  --light: #7B828F;
  --light-grey: #F0F2F7;
  --extra-light-grey: #F9FAFC;
  --white: #FFF;
  /* SYSTEM COLORS */
  --alert: #C9424A;
  --warning: #AD9914;
  --success: #56A14D;
  --alert-light: rgba(201, 66, 74, 0.1);
  --warning-light: rgba(173, 153, 20, 0.1);
  --warning-success: rgba(86, 161, 77, 0.1);
   /* OPTIONAL COLORS */
  --pink: #D02B6E;
  --light-blue: #4590C4;
  --purple: #5D27CD;
  --pink-light: rgba(208, 43, 110, 0.1);
  --light-blue-light: rgba(69, 144, 196, 0.1);
  --purple-light: rgba(93, 39, 205, 0.1);
}
/*
@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
   /*
  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #EF4E4C;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
   /*
  .ios body {
    --ion-background-color: white;
    --ion-background-color-rgb: 0,0,0;
    --ion-text-color: #25292E;
    --ion-text-color-rgb: 255,255,255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }
  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
   /*
  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
  }
}
*/