/* -----------------------------------------------------
	NAVBAR
----------------------------------------------------- */

.topbar {
  background-color: #000;
  z-index: 1051;
  color: #fff;
  padding: 14px 0;
}

.topBarContent {
  text-align: center;
  margin: 0 auto;
}

.topBarBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.spacer {
  height: 44px;
}

.header_navbar{
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1)); */
  z-index: 1050;
  position: fixed;
  transition: opacity .1s ease-in-out;
  opacity: 1;
}
ion-header{
  &::after{
    display: none;
  }
}
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	background: #FFFFFF;
  .container{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
}

.container{
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
  max-height: 100vh;
  overflow: hidden;
  @media (max-width: 767px) {
    overflow-y: scroll;
    overflow-x:hidden;
    padding-right: 15px;
	  padding-left: 15px;
  }
}

@media (min-width: 576px) {
	.container {
		max-width: 540px
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1140px
	}
}

.navbar_brand {
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
  ion-img{
    width: 72px;
    height: auto;
    transition: all .3 ease-in-out;
    @media (max-width: 767px) {
      width: 34px;
    }
  }
}

.navbar_toggler{
  margin-right: 0 !important;
  &::part(native){
    padding-right: 15px !important;
  }
  &:hover {
    text-decoration: none
  }
  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
  }
  .button_popover_icon{
    transform: rotate(0deg);
    transition: transform .1s ease-in-out;
    color: var(--secondary);
    &.open{
      transform: rotate(180deg);
    }
  }
}

.button_popover{
  margin: 0;
  &::part(native){
    overflow: inherit;
    font-weight: 400 !important;
    padding-left: 5px !important;
    background: var(--extra-light-grey);
    color: var(--primary);
  }
  ion-avatar{
    width: 30px;
    height: 30px;
    margin-inline-end: 10px;
  }
  ion-item{
    &::part(native){
      --padding-start: 0;
      --background: transparent;
    }
    &::part(detail-icon){
      transform: rotate(90deg);
      --detail-icon-font-size: 14px;
      color: #676767;
      opacity: 1;
    }
  }
}

.collapse:not(.show) {
  @media (max-width: 991px) {
    height: 0;
    overflow: hidden;
  }
}

.collapse{
	transition: height .5s ease-in-out;
}

.collapse.show{
	display: block;
  overflow: inherit;
} 

.navbar_collapse {
  align-items: center;
	flex-basis: 100%;
	transition: all .5s ease-in-out;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .5s ease;
}

.backdrop{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: black;
	opacity: 0;
	z-index: -1;
	min-height: 100vh;
	transition: opacity .3s ease-in-out;
  &.opening{
    display: block;
    opacity: 0;
  }
  &.open{
    display: block;
    opacity: .5;
  }
}

.navbar_nav {
	display: flex;
  justify-content: flex-end;
	flex-direction: column;
  align-items: center;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	background: #FFFFFF;
  margin-top: 0;
  @media (max-width: 767px) {
    margin-left: -12px;
    margin-right: -12px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.workspaces{
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  transition: border .15s ease-in-out;
  h5{
    color: var(--black);
    &:hover,
    &:focus,
    &:active{
      color: var(--black);
    }
  }
  ion-avatar{
    margin-right: 10px;
  }
  &.active{
    border: 2px solid var(--ion-color-primary);
  }
  }
  hr{
    margin: 0;
  }
  a.logout{
    align-items: center;
    display: flex;
    ion-icon{
      margin-left: 5px;
    }
  p span{
    font-weight: 500 !important;
  }
}

.workspaces_wrapper.no_desktop{
  // margin: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  width: calc(100% - 20px);
  @media (max-width: 991px) {
    box-shadow: none;
    padding: 0;
  }
}

.nav_item{
	text-align: end;
	border: 0;
  @media (max-width: 991px) {
    text-align: start;
  }
  a {
    cursor: pointer;
    display: block;
    padding: 0 !important;
    margin: 0rem 1rem;
    margin-right: 24px !important;
    text-decoration: none;
    color: var(--primary) !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    position: relative;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    &:after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0.5rem;
      left: 0;
      bottom: -3px;
      right: 0;
      width: 0;
      border-bottom: 1px solid var(--sad-purple);
      transition-duration: 0.3s;
      opacity: 0;
    }
    &:hover{
      color: var(--sad-purple);
      &:after{
        width: 100%;
        opacity: 0.25;
      }
    }
  }
  /* &.nav_item_button{
    margin-left: 16px;
    margin-right: 20px;
  } */
}

.nav_item.no_desktop {
  text-align: end;
  margin: 8px 24px 0 0;
  .logout{
    padding-top: 18.5px;
    padding-bottom: 18.5px;
    height: auto;
    width: auto;
    margin-right: 10px !important;
    margin-top: 0;
    margin-bottom: 0;
    &::part(native){
      overflow: visible;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
} 

@media (max-width: 991px) {
  .navbar-brand{
		margin-left: 1rem;
    ion-img{
      width: 44px;
    }
	}
  .navbar_toggler{
		margin-right: 1rem
	}
  .navbar_expand_lg .navbar_collapse {
		height: 100%;
	}
  .nav_item{
		border-bottom: 1px solid rgba(0,0,0,.125);
    width: 100%;
    &:last-of-type{
      border-bottom: 0px;
    }
    a{
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      margin: .5rem 1rem;
      margin-right: 24px !important;
      &:after{
        display: none;
      }
    }
    &.nav_item_button ion-button{
      margin: 16px 1rem;
      margin-right: 24px !important;
    }
	}
}

@media (min-width: 992px) {
	.navbar_expand_lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
    .navbar_collapse {
      display: flex!important;
      flex-basis: auto;
      margin-left: auto;
    }
    .navbar_nav{
      flex-direction: row;
    }
	}
}

.nav_common_user{
  height: 460px;
}

.nav_not_common_user{
  height: 627px;
}