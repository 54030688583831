.patient_list_item{
  box-shadow: none !important;
  border-top: 1px solid var(--light-grey);
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 5px solid transparent !important;
  z-index: 1;
  margin: 0 !important;
  transition: all .3s ease-in-out;
  border-radius: 0 !important;
  background: var(--ion-color-light);
  &.active,
  &:hover{
    border-left: 5px solid var(--light-grey) !important;
    border: 1px solid var(--light-grey);
    box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.05) !important;
    z-index: 2;
    ion-button:not([disabled]),
    ion-img,
    .patient_list_item_tags_id{
      opacity: 1;
    }
  }
  &.active{
    .right_line{
      border-right: 1px solid var(--light-grey);
      @media (max-width: 767px) {
        border-right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    border-right: none;
    border-left: none;
    background: var(--ion-color-light);
    ion-button:not([disabled]),
    ion-img,
    .patient_list_item_tags_id{
      opacity: 1 !important;
      margin-left: 0 !important;
    }
    &.active,
    &:hover{
      border-right: none !important;
      border-left: none !important;
      box-shadow: none !important;
    }
  }
  ion-button:not([disabled]){
    opacity: .1;
    transition: opacity .3s ease-in-out;
  }
  ion-img,
  .patient_list_item_tags_id{
    opacity: .3;
    cursor: default;
    transition: opacity .3s ease-in-out;
  }
  ion-button ion-icon{
    font-size: 24px;
    @media (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  ion-icon{
    font-size: 18px;
  }
  ion-col{
    padding: 0;
  }
  ion-card-header{
    padding: 6px 0;
  }
  .patient_list_item_column{
    position: relative;
    padding-right: 40x;
    white-space: nowrap;
    overflow: hidden;
    p:last-child{
      white-space: nowrap;
      overflow: hidden;
    }
    &:after{
      content: "";
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
    .line{
      background: var(--light-grey);
      width: calc(100% - 44px);
      height: 1px;
      margin-left: auto;
      margin-top: 15px;
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
  .patient_list_item_report_state{
    margin-right: 1em;
    &.registered,
    &.received,
    &.processing{
      color: var(--primary);
    }
    &.exception{
      color: var(--alert);
    }
    &.ready_to_review{
      color: var(--purple);
    }
    /* &.ready_to_release{
      color: var(--ion-color-success);
    } */
    &.released{
      color: var(--light);
    }
    @media (max-width: 991px) {
      margin-top: 10px;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      margin-right: 0px;
    }
  }
  .patient_list_item_tags_id{
    background-color: var(--primary-light);
    padding: 2px 6px;
    border-radius: 5px;
    @media (max-width: 991px) {
      margin-top: 10px;
      display: inline-block;
    }
  }
  .patient_list_item_report_row{
    padding: 18px 0 18px 20px;
    border-bottom: 1px dashed var(--light-grey);
    &:last-of-type{
      border-bottom: none;
    }
    @media (max-width: 991px) {
      padding-left: 44px;
      padding-right: 44px;
    }
    @media (max-width: 767px) {
      padding-left: 44px;
      padding-bottom: 5px;
      padding-right: 0px;
    }
    ion-img{
      min-width: 24px;
    }
  }
  .patient_list_item_expandable_cat_data{
    margin-left: 44px;
    padding-right: 20px;
    div{
      margin-bottom: 15px;
      p{
        margin: 0;
        white-space: normal !important;
        overflow: auto !important;
      }
    }
    @media (max-width: 767px) {
      margin-left: 44px;
      margin-top: 0 !important;
      div{
        margin-bottom: 5px;
      }
    }
  }
  .patient_list_item_expandable_report_data{
    ion-icon{
      color: var(--light);
      font-size: 12px;
      min-width: 12px;
    }
    ion-img{
      min-width: 12px;
      width: 12px;
      height: 12px;
      opacity: 1 !important;
    }
    a{
      color: var(--dark-grey);
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      margin-top: 15px !important;
      .patient_list_item_expandable_info_report_col1{
        order: 2;
        padding-right: 20px;
        margin-top: 20px;
        div{
          padding-left: 0 !important;
          margin-bottom: 10px;
        }
      }
      .patient_list_item_expandable_info_report_col2{
        order: 1;
      }
    }
  }
  .patient_list_item_expandable_share_banner{
    background: #FFF;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 2em 20px 20px 0px;
    border: 1px solid rgba(72, 67, 102, 0.1);
    border-left: 4px solid transparent;
    &.review{
      border-left-color: var(--purple);
    }
    &.exception{
      border-left-color: var(--alert);
    }
    &.released{
      border-left-color: var(--light);
    }
    @media (max-width: 767px) {
      margin: 10px 20px 0 0;
    }
  }
  .patient_list_item_expandable_info_banner{
    margin: 2em 20px 20px 0px;
    padding: 12px 18px;
    background: var(--extra-light-grey);
    border-radius: 5px;
    @media (max-width: 767px) {
      margin: 10px 20px 0 0;
    }
  }

  .customIconColor {
    ion-img{
      // TODO: <IonImg> is not passing the color prop to the svg, meaning we have to 'assign' color by the filter prop.
      // Steps to get #7B828F are set by https://codepen.io/sosuke/full/Pjoqqp
      filter: brightness(0) saturate(100%) invert(52%) sepia(12%) saturate(350%) hue-rotate(180deg) brightness(95%) contrast(91%);
    }
  }
}

.overflow_text{
  white-space: nowrap; 
  overflow: hidden;
  position: relative;
  padding-right: 30px;
  margin-right: 15px;
  &::after{
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.state_icon{
  font-size: 18px;
  min-width: 18px;
  max-width: 18px;
}

.textCapitalized{
  text-transform: capitalize;
}