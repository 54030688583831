.card_rf_log{
  box-shadow: none !important;
  border-top: 1px dashed var(--light-grey);
  padding: 0px 10px 0px 15px;
  margin: 0 !important;
  border-left: 5px solid transparent;
  transition: all .3s ease-in-out;
  border-radius: 0 !important;
  background: transparent;
  &:hover,
  &.active{
    border-left: 5px solid var(--light-grey);
    background: #FFF;
    box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.05) !important;
    ion-button{
      opacity: 1;
      cursor: pointer;
    }
    .card_rf_log_report_state_icon_danger_label{
      display: block;
    }
    .card_rf_log_report_state_icon{
      font-size: 18px !important;
      min-width: 18px;
    }
    .tag{
      opacity: 1;
    }
  }
  .tag{
    opacity: 0.1;
  }
  ion-button{
    opacity: 0.1;
    cursor: none;
  }
  .card_rf_log_column{
    position: relative;
    padding-right: 40x;
    white-space: nowrap;
    overflow: hidden;
    p:last-child{
      white-space: nowrap;
      overflow: hidden;
    }
    &:after{
      content: "";
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
    @media (max-width: 1020px) {
      div{
        margin-left: 2px;
      }
    }
  }
  .card_rf_log_tags_id{
    background-color: rgba(62, 64, 84, 0.1);
    padding: 2px 6px;
    border-radius: 5px;
  }
  .card_rf_log_states{
    svg {
      width: 24px;
    }
    ion-icon{
      font-size: 24px;
    }
    &.pending {
      path{
        fill: var(--warning);
      }
      ion-icon{
        color: var(--warning);
      }
    }
    &.submitted {
      path{
        fill: var(--primary);
      }
      ion-icon{
        color: var(--primary);
      }
    }
  }
  .card_rf_log_report_state_icon{
    font-size: 18px;
    &.registered{
      color: var(--purple);
    }
    &.received{
      color: var(--light-blue);
    }
    &.processing{
      color: var(--ion-color-warning);
    }
    &.exception{
      color: var(--ion-color-danger);
    }
    &.ready_to_release{
      color: var(--ion-color-success);
    }
    &.released{
      color: var(--medium-grey);
    }
  }
  .card_rf_log_report_state_icon_danger_label{
    display: none;
    &.expection{
      display: block;
    }
  }
  @media (max-width: 1020px) {
    border: none;
    border-bottom: 1px solid var(--light-grey);
    background: var(--ion-color-light);
    box-shadow: none;
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover{
      border-left: 0px;
      box-shadow: none;
    }
    ion-col{
      padding: 0;
    }
    ion-button{
      opacity: 1;
      cursor: pointer;
    }
    .card_rf_log_report_state_icon_danger_label{
      display: block;
    }
    .card_rf_log_report_state_icon{
      font-size: 18px !important;
      min-width: 18px;
    }
    .tag{
      opacity: 1;
      margin-left: 1em;
    }
  }
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    margin: 0 5px !important;
  }
  ion-img{
    min-width: 24px;
  }
}

.justify_content_md_between{
  justify-content: start;
  @media (max-width: 767px) {
    justify-content: space-between;
  }
}
.justify_content_xl_end{
  justify-content: end;
  @media (max-width: 1199px) {
    justify-content: start;
  }
}
.justify_content_sm_between{
  justify-content: start;
  @media (max-width: 991px) {
    justify-content: space-between;
  }
}

.mt_sm_3{
  @media (max-width: 991px) {
    margin-top: 1em;
  }
}
.mt_sm_2{
  @media (max-width: 991px) {
    margin-top: .5em;
  }
}
.mt_sm_1{
  @media (max-width: 991px) {
    margin-top: .25em;
  }
}
.mb_sm_2{
  @media (max-width: 991px) {
    margin-bottom: .5em;
  }
}
.ml_lg_3{
  @media (min-width: 992px) {
    margin-left: 1em;
  }
}

.flex_sm_reverse{
  @media (max-width: 991px) {
    flex-direction: row-reverse;
  }
}

.overflow_text{
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  &:after{
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.text_no_wrap{
  white-space: nowrap;
}