
.select{
  max-height: 50px;
  font-size: 15px;
  margin-bottom: 9px;
  margin-top: 0 !important;
  &.disabled{
    ion-icon,
    .css-1wa3eu0-placeholder{
      opacity: .5;
    }
    
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
  .selectInput{
    display: block !important;
  }
  input{
    padding-top: 0 !important;
    padding-bottom: 0 !important;    
    width: 100% !important;
  }
  ion-icon{
    width: 18px;
    height: 18px;
  }
  .first_name{
    display: inline;
  }
  .last_name{
    display: inline;
  }
  .email{
    display: inline;;
  }
  .new{    
    color:#3880ff;
    font-weight: bold;
    .icon{
      float:left;
      padding-right: 10px;        
    }
  }
  .group {
    font-weight: bold;
    color:#151719b5
  }
  > div{
    &::after {
      content: '';
      position: absolute;
      display: block;
      inset: -1px;
      opacity: 0;
      border-radius: 8px!important;
      border: 2px solid var(--ion-color-secondary)!important;
      transition-duration: .3s;
      pointer-events: none;
    }
    &.css-163pkj4-control {
      box-shadow: none!important;
      &::after{
        opacity: 1;
      }
    }
  }
}

.bold{
  font-weight: bold;
}

.css-1gszsqk-control:hover{
  border-color: #F0F2F7!important;
}

.css-1hwfws3{
  max-height: 50px !important;
}
.css-yk16xz-control,
.css-uccvfe-control,
.css-1v90szp-control{
  max-height: 50px;
  border-color: var(--light-grey) !important;
  border-radius: 5px !important; 
}

.css-1pahdxg-control{
  &:hover,
  &:focus{
    border-color: var(--accent) !important;
    border-width: 2px;
    box-shadow: 0px 0px 6px var(--accent);
  }
}
.css-1hb7zxy-IndicatorsContainer{
  padding-left: 16px;
}
.css-1okebmr-indicatorSeparator{
  background-color: var(--light-grey) !important;
}
.css-g1d714-ValueContainer{
  max-height: 50px;
  position: initial !important;
}
.css-1uccc91-singleValue{
  //margin: auto 2px !important;
  //position: relative !important;
  //top: 13px !important;
  top: 60%;
}
.css-b8ldur-Input{
  max-height: 50px;
  input{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.form input{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.select_disabled{
  opacity: .4;
  .css-1fhf3k1-control{
    background-color: #fff;
  }
}