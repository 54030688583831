.modal_right {
  .modal_close_button{
    color: var(--ion-color-dark);
  }
  .modal_content{
    padding: 0px 30px 30px 30px;
    @media (max-width: 767px) {
      padding: 0px 30px 40px 30px;
    }
  }
  ion-avatar{
    width: 45px;
    height: 45px;
  }
  ion-header::after{
    display: none;
  }
  ion-title{
    padding-inline: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}