.invalidEmail {
  background-color: var(--alert-light);
  
  &:not(&:global(.has-focus)){
    border: 1px solid var(--alert) !important; //TODO important needed because the styles of ".form .ion-input" from general.scss overrides this
  }
}

.submitButton{
  margin-top: 16px;
}

.feedback{
  margin-top: 16px;
}