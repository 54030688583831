/* -----------------------------------------------------
	MARGINS & PADDINGS
----------------------------------------------------- */
/* General */
.m-0{
  margin: 0 !important;
}
.m-1{
  margin: .25em !important;
}
.m-2{
  margin: .5em !important;
}
.m-3{
  margin: 1em !important;
}
.m-4{
  margin: 2em !important;
}
.m-5{
  margin: 3em !important;
}
.p-0{
  padding: 0 !important;
}
.p-1{
  padding: .25em !important;
}
.p-2{
  padding: .5em !important;
}
.p-3{
  padding: 1em !important;
}
.p-4{
  padding: 2em !important;
}
.p-5{
  padding: 3em !important;
}

/* Vertical */
.my-0{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1{
  margin-top: .25em !important;
  margin-bottom: .25em !important;
}
.my-2{
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}
.my-3{
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.my-4{
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}
.my-5{
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}
.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1{
  padding-top: .25em !important;
  padding-bottom: .25em !important;
}
.py-2{
  padding-top: .5em !important;
  padding-bottom: .5em !important;
}
.py-3{
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.py-4{
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}
.py-5{
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}

/* Horizontal */
.mx-0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1{
  margin-left: .25em !important;
  margin-right: .25em !important;
}
.mx-2{
  margin-left: .5em !important;
  margin-right: .5em !important;
}
.mx-3{
  margin-left: 1em !important;
  margin-right: 1em !important;
}
.mx-4{
  margin-left: 2em !important;
  margin-right: 2em !important;
}
.mx-5{
  margin-left: 3em !important;
  margin-right: 3em !important;
}
.mx-auto{
  margin: 0 auto;
}
.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1{
  padding-left: .25em !important;
  padding-right: .25em !important;
}
.px-2{
  padding-left: .5em !important;
  padding-right: .5em !important;
}
.px-3{
  padding-left: 1em !important;
  padding-right: 1em !important;
}
.px-4{
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.px-5{
  padding-left: 3em !important;
  padding-right: 3em !important;
}

/* Top */
.mt-0{
  margin-top: 0 !important;
}
.mt-1{
  margin-top: .25em !important;
}
.mt-2{
  margin-top: .5em !important;
}
.mt-3{
  margin-top: 1em !important;
}
.mt-4{
  margin-top: 2em !important;
}
.mt-5{
  margin-top: 3em !important;
}
.pt-0{
  padding-top: 0 !important;
}
.pt-1{
  padding-top: .25em !important;
}
.pt-2{
  padding-top: .5em !important;
}
.pt-3{
  padding-top: 1em !important;
}
.pt-4{
  padding-top: 2em !important;
}
.pt-5{
  padding-top: 3em !important;
}
.mt-sm{
  margin-top: 0px;
  @media (max-width: 767px) {
      margin-top: 16px;
  }
}


/* Bottom */
.mb-0{
  margin-bottom: 0 !important;
}
.mb-1{
  margin-bottom: .25em !important;
}
.mb-2{
  margin-bottom: .5em !important;
}
.mb-3{
  margin-bottom: 1em !important;
}
.mb-4{
  margin-bottom: 2em !important;
}
.mb-5{
  margin-bottom: 3em !important;
}
.pb-0{
  padding-bottom: 0 !important;
}
.pb-1{
  padding-bottom: .25em !important;
}
.pb-2{
  padding-bottom: .5em !important;
}
.pb-3{
  padding-bottom: 1em !important;
}
.pb-4{
  padding-bottom: 2em !important;
}
.pb-5{
  padding-bottom: 3em !important;
}

/* Left */
.ml-0{
  margin-left: 0 !important;
}
.ml-1{
  margin-left: .25em !important;
}
.ml-2{
  margin-left: .5em !important;
}
.ml-3{
  margin-left: 1em !important;
}
.ml-4{
  margin-left: 2em !important;
}
.ml-5{
  margin-left: 3em !important;
}
.pl-0{
  padding-left: 0 !important;
}
.pl-1{
  padding-left: .25em !important;
}
.pl-2{
  padding-left: .5em !important;
}
.pl-3{
  padding-left: 1em !important;
}
.pl-4{
  padding-left: 2em !important;
}
.pl-5{
  padding-left: 3em !important;
}

/* Right */
.mr-0{
  margin-right: 0 !important;
}
.mr-1{
  margin-right: .25em !important;
}
.mr-2{
  margin-right: .5em !important;
}
.mr-3{
  margin-right: 1em !important;
}
.mr-4{
  margin-right: 2em !important;
}
.mr-5{
  margin-right: 3em !important;
}
.pr-0{
  padding-right: 0 !important;
}
.pr-1{
  padding-right: .25em !important;
}
.pr-2{
  padding-right: .5em !important;
}
.pr-3{
  padding-right: 1em !important;
}
.pr-4{
  padding-right: 2em !important;
}
.pr-5{
  padding-right: 3em !important;
}
