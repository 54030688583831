.disclaimer{
  display: flex;
  border-radius: 5px;
  padding: 10px 16px;
  margin: 30px 0;
  ion-icon{
    font-size: 18px;
    min-width: 18px;
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    margin: 20px 5px;
  }
  &.m_0{
    margin: 0;
  }
  &.mx_7{
    margin: 7px 0;
  }
  &.pink{
    background-color: var(--pink-light);
    ion-icon,
    a{
      color: var(--pink);
    }
  }
  &.red{
    background-color: var(--alert-light);
    ion-icon,
    a{
      color: var(--alert);
    }
  }
  &.green{
    background-color: var(--warning-success);
    ion-icon,
    a{
      color: var(--success);
    }
  }
  &.blue{
    background-color: var(--accent-light);
    ion-icon,
    a{
      color: var(--accent);
    }
  }
  &.blue_secondary{
    background-color: #FFF;
    ion-icon,
    a{
      color: var(--accent);
    }
  }
}