.my_profile_pendingtask_card{
  ion-card-header{
    padding: 20px 30px 8px;
    ion-card-title{
      padding: 14px 0;
    }
    a{
      display: flex;
      align-items: center;
      color: var(--dark-grey);
      &:hover{
        color: var(--black);
        ion-card-title{
          color: var(--black) !important;
        }
      }
    }
  }
  ion-card-content{
    padding: 15px 25px 25px 25px !important;
    ion-list{
      padding-top: 0 !important;
    }
    .my_profile_pendingtask_card_item{
      padding: 15px 0 0;
      border-top: 1px solid var(--light-grey);
      ion-avatar{
        height: 30px;
        width: 30px;
        margin-right: 10px;
        img{
          width: 100%;
        }
      }
    }
  }
  .card_footer{
    padding: 20px !important;
  }
}

.my_profile_pendingtask_card_empty{
  position: relative;
  overflow: visible;
  img{
    position: absolute;
    right: 30px;
    bottom: 0px;
    max-width: 180px;
  }
  .card_footer{
    padding: 44px !important;
  }
}


@media (max-width: 991px) { 
  .my_profile_pendingtask_card{
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 10px 20px 13px;
      .message{
        margin-top: 5px;
      }
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
      ion-col{
        padding: 0 !important;
      }
      .my_profile_pendingtask_card_item{
        p{
          margin-top: 10px;
        }
        ion-avatar{
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
        ion-button{
          margin-left: -5px;
        }
      }
    }
  }
  .my_profile_pendingtask_card_empty{
    margin-right: 5px !important;
    margin-left: 5px !important;
    ion-card-header{
      padding: 20px;
    }
    ion-card-content{
      padding: 0px 20px 10px 20px !important;
      ion-col{
        padding: 0 !important;
      }
    }
    .card_footer{
      padding: 0px !important;
    }
    img{
      max-width: 180px;
      position: relative;
      display: block;
      margin-left: auto;
      right: 15px;
    }
  }
}

