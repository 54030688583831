/* -----------------------------------------------------
	FOOTER
----------------------------------------------------- */
ion-footer{
  margin-top: 60px;
  padding-bottom: 35px;
  &:before{
    display: none;
  }
  .pattern{
    height: 22px;
    width: auto;
    background-repeat: no-repeat;
    background-image: url('../../../../theme/basepaws-footer-pattern-2023.svg');
    background-size: cover;
  }
  .email_us_wrapper{
    display: flex;
    align-items: center;
    p:first-child{
        margin-right: 5px;
    }
  }
  .links_wrapper{
    text-align: right;
    p a{
        text-decoration: none;
        color: var(--black);
        &:hover{
            color: #575f66;
        }
    }
  }
  .footer_brand{
      max-height: 14px;
  }
  .footer_credentials{
    text-align: right;
    p{
      margin-top: 0;
      font-size: 12px;
      line-height: 16px;
      color: var(--grey);
    }
  }
  p{
    margin: 8px 0;
  }
  @media (max-width: 767px) {
    padding-bottom: 15px;
    .pattern{
        height: 30px;
    }
    .links_wrapper{
        text-align: left;
    }
    .footer_credentials{
        text-align: left;
    }
  }
  @media (max-width: 991px) {
    .email_us_wrapper{
      display: block;
    }
  }
}
