.empty_section{
  padding: 80px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  ion-img{
    max-width: 220px !important;
    margin: 0 auto;
  }
  h1{
    margin-bottom: 0;
  }
  p{
    margin-bottom: 50px;
    margin-top: 15px;
  }
}