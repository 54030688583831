.formContainer{
  display: flex;
  align-items: flex-start;

  .submit{
    margin: 0 !important;
  }

  .errorLabel{
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: var(--alert);
    max-width: 124px;
    margin: auto;
    padding-top: 8px;
  }

  .inputContainer{
    padding-right: 4px;
  }
}

.barcodeInput{
  text-align: center;
  max-width: 162px;
  border: 2px solid var(--light-blue-light) !important;
  border-radius: 25px;
  background-color: var(--accent-light);

  input{
    padding-left: 0 !important;
  }

  &.invalid{
    border: 2px solid var(--alert) !important;
  }  

  &::after{
    border: 2px solid var(--light-blue) !important;
    border-radius: 25px !important;
  }

  &.invalid::after{
    border: 2px solid var(--alert) !important;
  }  
}

.info{
  &.alert{
    --max-width: 540px;
    font-family: 'Jakarta';

    :global(.alert-head){
      padding: 60px 60px 10px;
      text-align: center;

      :global(.alert-title){
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        color: var(--primary);
      }
    }

    :global(.alert-message){
      padding: 0px 60px;
      text-align: center;
      font-size: 15px;
      line-height: 24px;
      color: var(--grey);
    }

    :global(.alert-button-group){
      padding: 20px 60px 60px;
      justify-content: center;
    }

    :global(.alert-button){
      border-radius: 20px;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      text-transform: none;
      padding-left: 24px;
      padding-right: 24px;
      margin-left: 5px;
      margin-right: 5px;
    }

    :global(.alert-button-role-cancel){
      border: 1px solid var(--grey);
      color: var(--grey);
    }

    :global(.alert-button-role-confirm){
      background-color: var(--accent);
      color: var(--white);
    }

    @media (max-width: 767px) {
      --max-width: 280px;
    }
  }
}