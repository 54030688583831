.remove {
  width: unset !important;
  flex: unset !important;
  max-width: unset !important;
}
.new_form_styles {
	input,
	ion-input,
	ion-datetime,
	.input_radio,
	ion-textarea {
		border-radius: 8px;
	}
	ion-input[disabled="true"] {
		ion-img,
		ion-icon {
			opacity: 0.4;
		}
	}
	ion-icon {
		color: var(--light);
	}
	ion-checkbox {
		/* --background-checked: var(--light) !important;
    --border-color-checked: var(--light) !important; */
		&::part(container) {
			height: 16px;
			width: 16px;
		}
	}
	.input_radio_wrapper {
		margin-right: 0.5em;
	}
	.input_radio {
		margin-top: 0.25em;
		ion-radio {
			min-width: 20px;
		}
		@media (max-width: 768px) {
			margin-bottom: 10px;
			margin-top: 0;
			ion-item {
				&::part(native) {
					padding-left: 5px;
				}
			}
		}
		ion-checkbox {
			margin-right: 10px !important;
			margin: 15px 10px 15px 0 !important;
			@media (max-width: 768px) {
				margin: 12px 10px !important;
			}
		}
	}
}

.input_w_300 {
	max-width: 300px;
	margin-left: 0;
	@media (max-width: 990px) {
		width: 100%;
		max-width: none;
	}
}
.input_w_100 {
	max-width: 100px;
	@media (max-width: 990px) {
		width: 100%;
		max-width: none;
	}
}

.input_disabled_filled {
	background-color: var(--light);
	input {
		color: var(--light) !important;
		opacity: 1 !important;
	}
	ion-icon {
		--ion-color-base: var(--light) !important;
	}
}
.data_disabled {
	opacity: 0.3;
	pointer-events: none;
}

.data_disabled {
	input,
	.input_radio,
	.input_checkbox,
	ion-button {
		opacity: 0.3;
		pointer-events: none;
	}
}

.precharged_data {
	ion-icon {
		--ion-color-base: var(--light) !important;
		min-width: 16px;
	}
	&.multiple {
		p {
			margin: 8px 0 !important;
		}
		ion-img,
		ion-icon {
			margin: 8px 8px 8px 0 !important;
		}
	}
	&.multiple_first {
		p {
			margin-bottom: 8px !important;
		}
		ion-img,
		ion-icon {
      width: 16px;
      font-size: 16px;
			margin-top: 15px;
			margin-bottom: 8px;
		}
	}
}

.disclaimer {
	display: flex;
	border-radius: 5px;
	padding: 10px 16px;
	margin: 30px 0;
	ion-icon {
		font-size: 18px;
		min-width: 18px;
		margin-right: 10px;
	}
	@media (max-width: 768px) {
		margin: 20px 5px;
	}
	&.pink {
		background-color: var(--pink-light);
		ion-icon,
		a {
			color: var(--pink);
		}
	}
	&.blue {
		background-color: var(--accent-light);
		ion-icon,
		a {
			color: var(--accent);
		}
	}
}

.requisition_form_header {
	background: #fff;
	padding: 15px 0;
	box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.05);
	margin: -5px;
	@media (max-width: 768px) {
		padding: 5px 0;
		margin-left: -5px;
		margin-right: -5px;
		ion-col:last-of-type {
			margin-top: -10px;
		}
	}
	ion-button {
		z-index: 4;
	}
}
.tag {
	padding: 7px 30px 7px 24px;
	border-radius: 20px 0px 0px 20px;
	border: 1px solid rgba(69, 116, 193, 0.06);
	border-right: none;
	@media (max-width: 768px) {
		max-width: 235px;
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			display: block;
			right: 0;
			top: 0%;
			bottom: 0;
			width: 30px;
			height: 100%;
			background: linear-gradient(
				270deg,
				#ffffff 0%,
				#ffffff 39.58%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}
}

.input_message_info {
	padding: 11px 15px;
	ion-icon {
		min-width: 18px;
		font-size: 18px;
		margin-top: 1spx;
	}
}

.labels_wrapper {
	text-align: right;
	p {
		text-align: right;
	}
	@media (max-width: 768px) {
		text-align: left;
		p {
			text-align: left;
		}
	}
	p.multiple {
		margin: 9px 8px 9px 0 !important;
	}
}

.button_light_blue {
	margin-left: -12px;
	--background: #f4f7fb !important;
	--ion-color-base: #f4f7fb !important;
	color: var(--accent) !important;
	&::part(native) {
		--ion-color-base: #f4f7fb !important;
		color: var(--accent);
	}
	&:hover {
		&::part(native) {
			--ion-color-base: var(--accent) !important;
			--background: var(--accent);
			color: var(--white);
		}
	}
}

.d_md_flex {
	display: flex;
	@media (max-width: 768px) {
		display: block;
	}
	@media (max-width: 990px) {
		ion-input:last-child {
			margin-left: 5px;
		}
		ion-input:first-child {
			margin-right: 5px;
		}
	}
}

.d_lg_flex {
	display: flex;
	@media (max-width: 990px) {
		display: block;
	}
}

@media (min-width: 768px) {
	.mr_md_0 {
		margin-right: 0;
	}
	.ml_md_0 {
		margin-left: 0;
	}
	.mr_md_1 {
		margin-right: 0.25em;
	}
	.ml_md_1 {
		margin-left: 0.25em;
	}
	.mr_md_2 {
		margin-right: 0.5em;
	}
}
@media (max-width: 768px) {
	.mr_sm_0 {
		margin-right: 0 !important;
	}
	.mb_sm_3 {
		margin-bottom: 1em;
	}
	.col_sm_p0 {
		padding: 0;
	}
	.d_sm_block {
		display: block;
	}
}

.button_min_width1 {
	min-width: 137px;
}
.button_min_width2 {
	min-width: 148px;
}
.col_pb_0 {
	ion-col:last-child {
		padding-bottom: 1px !important;
		ion-item {
			margin: 0;
		}
	}
}

.no_label_col {
	ion-col:first-child {
		@media (max-width: 768px) {
			display: none;
		}
	}
}

.patient_fields {
	ion-col {
		padding-bottom: 0;
		padding-top: 0;
	}
}

.checkbox_no_border {
	ion-item {
		border: none !important;
		&::part(native) {
			padding-left: 0;
		}
	}
	&.checkbox_p {
		ion-item {
			&::part(native) {
				padding-left: 15px;
			}
		}
	}
	&.align_content_start {
		ion-item {
			&::part(native) {
				align-items: flex-start;
			}
		}
	}
}
