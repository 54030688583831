.accordion {
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  padding: 0 !important;
  ion-icon{
    font-size: 24px !important;
    color: var(--medium-grey);
  }
  &.line{
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    @media (max-width: 767px) {
      padding-top: 10px !important;
    }
  }
  &.view_detail_button{
    ion-col{
      padding: 0 !important;
      text-align: left !important;
      max-width: 22px !important;
    }
    ion-icon{
      font-size: 16px !important;
      color: var(--ion-color-primary);
    }
  }
  &.research_program_accordion{
    ion-card-header ion-col{
      width: auto !important;
      max-width: 14% !important;
    }
  }
  &.patient_list{
    ion-row{
      flex-wrap: nowrap !important;
    }
    ion-icon{
      color: var(--ion-color-primary);
    }
    ion-card-header ion-col{
      width: auto !important;
      max-width: 20% !important;
      ion-button{
        min-width: 40px;
      }
      @media (max-width: 991px) {
        max-width: 7% !important;
      }
      @media (max-width: 767px) {
        max-width: 12% !important;
      }
    }
  }
  &.view_more_button{
    margin-top: -20px;
    ion-card-header{
      padding: 0;
    }
    ion-button{
      --height: 20px;
      min-height: 20px;
      margin: 0;
      font-size: 13px;
      line-height: 15px;
      margin-left: -2px;
    }
    @media (max-width: 991px) {
      margin-top: -10px;
    }
  }
}

.active,
.accordion:hover {
  background-color: transparent;
}

.panel {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  .item.sc-ion-label-md-h, .item .sc-ion-label-md-h,
  .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    white-space: inherit;
  }
  &.open{
    max-height: 100%;
  }
}

