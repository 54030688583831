.card_information{
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  box-shadow: none;
  margin: 5px 0 0 0!important;
  padding: 15px !important;
  .tags_id{
    background-color: var(--primary-light);
    padding: 2px 6px;
    border-radius: 5px;
    margin: 2px 0 2px 27px;
  }
}

.box{
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  padding: 0 15px !important;
  ion-button{
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.message_missing_email{
  background: rgba(72, 67, 102, 0.05);
  border-radius: 5px;
  padding: 9px 16px 10px;
  display: flex;
  /* align-items: center; */
  ion-icon{
    margin-right: .5em;
    min-width: 18px;
    margin-top: 2px;
  }
}