ion-card-header{
  padding: 0;
}
.card_footer{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.card_content{
  padding: 0 0 25px;
  ion-col{
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .card_content{
    padding: 0;
  }
}

.text_md_right{
  text-align: right;
  @media (max-width: 991px) {
    text-align: left;
  }
}

.mb_md_5{
  margin-bottom: 3em;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.absolute_position{
  position: absolute !important;
}
