@keyframes rotateAnimation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  height: 1em;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--light-blue);
  animation: rotateAnimation 1000ms infinite linear;
}

.loadingWrapper {
  // we need this because the bounding box of .loading rotates, which causes it to change size (this can only be noted
  // by selecting the element in the devtools), and that wrecks havoc with the layout
  overflow: hidden;
}
