/* -----------------------------------------------------
	FONTS
----------------------------------------------------- */
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-Bold.ttf') format('truetype');
	font-weight: 700;
  font-weight: bold;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
  font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-Light.ttf') format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'Jakarta';
	src: url('../fonts/PlusJakartaSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Ionicons';
	src: url('../fonts/ionicons.ttf') format('truetype');
}

.md {
	--ion-font-family: 'Jakarta', Roboto, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Noto, Helvetica, Arial, sans-serif;
}
.ios {
	--ion-font-family: 'Jakarta', -apple-system, SF Pro Text, SF UI Text, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Helvetica, Arial, sans-serif;
}