/* -----------------------------------------------------
	HEADER
----------------------------------------------------- */
ion-header{
  &.header_center,
  &.header_left,
  &.header_action_left,
  &.header_action_right{
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    ion-toolbar{
      --border-width: 0 !important;
    }
    @media (max-width: 991px) {
      padding-bottom: 0;
    }
  }
}

.header_center{
  ion-title{
    text-align: center !important;
  }
}
.header_left{
  ion-title{
    text-align: left;
  }
}

.header_action_left{
  ion-title{
    text-align: center;
  }
  ion-toolbar{
    position: relative;
    ion-buttons{
      position: absolute;
      left: 0;
    }
  }
}
.header_action_right{
  ion-title{
    text-align: center;
  }
  ion-toolbar{
    position: relative;
    ion-buttons{
      position: absolute;
      right: 0;
    }
  }
  @media (max-width: 991px) {
    ion-title{
      text-align: left;
    }
  }
}

.pl_md_0{
  padding-left: 0;
  @media (max-width: 991px) {
    padding-left: 10px;
  }
}